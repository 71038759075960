import { NgModule, PLATFORM_ID, NgZone } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy, IonTextarea } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { environment } from '../environments/environment';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/functions';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GridModule } from '@progress/kendo-angular-grid';
import { ChartsModule } from '@progress/kendo-angular-charts';
import 'hammerjs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { SchedulerModule } from '@progress/kendo-angular-scheduler';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import {
  AngularfirestoreCoolStoreService,
  AngularfirestoreCoolStoreFactory,
  AngularfirestorePerfectStoreService,
  AngularfirestorePerfectStoreFactory,
  AngularfirestoreCoolStorageFactory,
  AngularfirestoreCoolStorageService,
  AngularfirestoreVideoStoreService,
  AngularfirestoreVideoStoreFactory,
  AngularfirestoreVideoStorageService,
  AngularfirestoreVideoStorageFactory,
} from './services/angularfirestore-extension.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    UserAvatarComponent
  ],
  entryComponents: [],
  imports: [
    HttpClientModule,
    BrowserModule,
    IonicModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireAuthGuardModule,
    AngularFireAnalyticsModule,
    AngularFireFunctionsModule,
    AppRoutingModule,
    ButtonsModule,
    BrowserAnimationsModule,
    GridModule,
    ChartsModule,
    LayoutModule,
    TooltipsModule,
    SchedulerModule,
    IndicatorsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: AngularfirestoreCoolStoreService, deps: [PLATFORM_ID, NgZone], useFactory: AngularfirestoreCoolStoreFactory },
    { provide: AngularfirestorePerfectStoreService, deps: [PLATFORM_ID, NgZone], useFactory: AngularfirestorePerfectStoreFactory },
    { provide: AngularfirestoreCoolStorageService, deps: [PLATFORM_ID, NgZone], useFactory: AngularfirestoreCoolStorageFactory },
    { provide: AngularfirestoreVideoStoreService, deps: [PLATFORM_ID, NgZone], useFactory: AngularfirestoreVideoStoreFactory },
    { provide: AngularfirestoreVideoStorageService, deps: [PLATFORM_ID, NgZone], useFactory: AngularfirestoreVideoStorageFactory },
    { provide: REGION, useValue: 'europe-west3' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }