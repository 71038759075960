// src/app/components/user-avatar/user-avatar.component.ts

import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
})
export class UserAvatarComponent implements OnInit {
  /**
   * The full name of the user. Used to extract the initial.
   */
  @Input() name: string = '';

  /**
   * The diameter of the avatar in pixels.
   */
  @Input() size: number = 40;

  /**
   * Optional: Custom background color. If not provided, a color is generated based on the name.
   */
  @Input() backgroundColor?: string;

  /**
   * Optional: Base64-encoded logo image string.
   * Example: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAA...'
   */
  @Input() logoBase64?: string;

  /**
   * Optional: Alternative text for accessibility.
   */
  @Input() altText: string = 'User avatar';

  /**
   * Event emitted when the avatar is clicked.
   */
  @Output() avatarClick = new EventEmitter<void>();

  /**
   * The initial to display inside the avatar.
   */
  initial: string = '';

  /**
   * The computed background color.
   */
  bgColor: string = 'gray'; // Default color

  ngOnInit() {
    this.initial = this.getInitial(this.name);
    this.bgColor = this.backgroundColor || this.generateColor(this.name);
  }

  /**
   * Extracts the first letter from the user's name.
   * If the name is empty, returns a question mark.
   */
  private getInitial(name: string): string {
    if (!name || name.trim().length === 0) {
      return '?';
    }
    return name.trim().charAt(0).toUpperCase();
  }

  /**
   * Generates a background color based on the user's name.
   * This ensures consistency across sessions for the same user.
   */
  private generateColor(name: string): string {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const hue = hash % 360;
    return `hsl(${hue}, 60%, 60%)`;
  }

  /**
   * Emits the avatarClick event when the avatar is clicked.
   */
  handleClick() {
    this.avatarClick.emit();
  }
}
