import { NgModule, LOCALE_ID } from '@angular/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import {
  GridModule
} from '@progress/kendo-angular-grid';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { DateInputsModule, DatePickerModule, MultiViewCalendarModule } from '@progress/kendo-angular-dateinputs';
import { IntlModule } from '@progress/kendo-angular-intl';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { TooltipModule } from '@progress/kendo-angular-tooltip';

import '@progress/kendo-angular-intl/locales/de/all';
import '@progress/kendo-angular-intl/locales/es/all';
import '@progress/kendo-angular-intl/locales/fr/all';
import '@progress/kendo-angular-intl/locales/it/all';
import '@progress/kendo-angular-intl/locales/en/all';
import { SchedulerModule } from '@progress/kendo-angular-scheduler';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { PopupModule } from '@progress/kendo-angular-popup';
import { LabelModule } from "@progress/kendo-angular-label";

@NgModule({
  exports: [
    ButtonsModule,
    GridModule,
    TreeViewModule,
    ChartsModule,
    LayoutModule,
    InputsModule,
    ExcelExportModule,
    DateInputsModule,
    DatePickerModule,
    DropDownsModule,
    MultiViewCalendarModule,
    SchedulerModule,
    LabelModule,
    TooltipModule,
    PopupModule,
    IndicatorsModule,
    IntlModule
  ],
  declarations: [
  ]
})

export class KendoModule { }
