import { Injectable, PLATFORM_ID, NgZone, ViewChild } from '@angular/core';
import {
  AngularfirestoreCoolStoreService,
  AngularfirestoreVideoStoreService,
} from './angularfirestore-extension.service'
import { from, Observable } from 'rxjs';
import { AngularFirestore } from 'angularfire2/firestore';
import firebase from "firebase/app";
import { FirebaseConstants } from '../shared/FirebaseConstants';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
//import * as admin from 'firebase-admin';
// const admin = require('firebase-admin');
import { Subscription } from 'rxjs';
import { RouterOutlet, Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { MenuService } from './menu-service';
import { AuthService } from './auth.service';
import { access } from 'fs';
import { add } from 'date-fns';
export interface FILE {
  name: string;
  filepath: string;
}

@Injectable({
  providedIn: 'root'
})
export class CustomerdatabaseService {

  public bewerber;
  public bewerbervertraege: firebase.firestore.CollectionReference;
  coolClctn$: Observable<any>
  perfectClctn$: Observable<any>
  app2 = null;
  auth: AngularFireAuth;
  customerStorage: AngularFireStorage;
  isPremium = false;
  isCustomer = false;
  isFreemium = false;
  customerNumber;
  newContractId = "";
  userId = "";
  static joinJobOffers = [];
  // userStorage;
  joinApiKey = "";
  JoinBewerber = [];
  HostingURL = "";
  teammemberId = "";
  db;
  calendar = "";
  cloudFunctionURL = "";
  cloudFunctionWaitingVideoURL = "";
  userEmail = "";
  public applicantProfile: firebase.firestore.CollectionReference;
  public monaactivities: firebase.firestore.CollectionReference;
  public applicantProfileImport: firebase.firestore.CollectionReference;
  public jobs: firebase.firestore.CollectionReference;
  public agentFolders: firebase.firestore.CollectionReference;
  customerAdminEmailAdress = "";
  subscription: Subscription;
  InternetStatus = "green"
  assessmentTests: any[] = [];
  businessName = "";
  businessLogo = "";
  @ViewChild(RouterOutlet) outlet: RouterOutlet;
  unsubscribe: Subscription;
  jobOfferSubscription: any;
  thirtyDaysAgo: Date = new Date();
  createdQuery: any;

  // AI disclaimers
  seenDisclaimer = false;
  confirmedDisclaimer = false;
  currentPlan = "";

  accountId = "";

  salesEmailsData: any;
  salesEmailDataHistory: any;
  companiesData: any;

  constructor(
    private coolStore: AngularfirestoreCoolStoreService,
    private videoStore: AngularfirestoreVideoStoreService,
    private zone: NgZone,
    private storage: AngularFireStorage,
    private http: HttpClient,
    private menuService: MenuService,
    private alertCtrl: AlertController,
    private authService: AuthService
  ) {
    this.applicantProfile = firebase.firestore().collection("applicantProfile");
    this.applicantProfileImport = firebase.firestore().collection("applicants");
    this.monaactivities = firebase.firestore().collection("activities");
    this.jobs = firebase.firestore().collection("JobsToCallApplicants");
    this.agentFolders = firebase.firestore().collection("agentFolders");
    // const source = interval(1000);
    // this.subscription = source.subscribe(val => this.speedtest());
    console.log("init customerDB");
    this.thirtyDaysAgo.setDate(this.thirtyDaysAgo.getDate() - 30);
  }

  sendContacting(testMail, testMobil, testFirstName, testLastName, filePath) {
    this.app2.firestore.collection("emailapplicants").add({
      createdEmailZapier: new Date(),
      email: testMail || "",
      mobilnummer: testMobil || "",
      vorname: testFirstName || "",
      nachname: testLastName || "",
      cv: filePath || "",
      emailTextHTML: "test",
      emailTextPlain: "test",
      senderEmail: "sales@mona-ai.fr"
    });
  }

  getCvPublicUrl() {

  }

  setcurrentPlan(plan) {
    this.currentPlan = plan || "";
    this.menuService.userRole$.next(plan);
    console.log("currentPlan", this.currentPlan);
  }

  returnCurrentPlan() {
    return this.currentPlan;
  }

  getAccountId() {
    return this.accountId;
  }

  getVideos() {
    this.videoStore.firestore.collection("videos").get().then((snap) => {
      console.log(snap);
    });
  }

  speedtest() {
    console.log("runnin")
    if (this.app2 == undefined) {
      // window.open("https://mona-ai-pwa-firebase.web.app")
    }
  }

  getUserData(email) {
    return this.coolStore.firestore.collection("customers");
  }

  getCustomerNumber() {
    return this.customerNumber;
  }

  saveCustomerNumber(customerNumber) {
    this.customerNumber = customerNumber;
  }

  getCloudFunctionURL() {
    return this.cloudFunctionURL;
  }

  getCloudFunctionWaitingVideoURL() {
    return this.cloudFunctionWaitingVideoURL;
  }

  saveSalesEmailsData(data, history, companies) {
    console.log("data", data);
    console.log("history", history);
    console.log("companies", companies);
    this.salesEmailsData = data;
    this.salesEmailDataHistory = history;
    this.companiesData = companies;
  }

  returnSalesEmailsData() {
    return this.salesEmailsData;
  }

  returnSalesEmailDataHistory() {
    return this.salesEmailDataHistory;
  }

  returnCompaniesData() {
    return this.companiesData;
  }

  returnSalesEmails() {
    return this.app2.firestore.collection("emailsToBeSent")
  }

  sendSalesEmails(id) {
    return this.app2.firestore.collection("emailsToBeSent").doc(id)
      .update({
        sent: true
      })
  }

  //Ray added this code
  async storeAssessmentTest(doc: any) {
    const collectionRef = this.app2.firestore.collection('assessmentTests')

    try {
      // Write a document to the 'assessmentTests' collection
      await collectionRef.add(doc);
      console.log('Document added to assessmentTests collection in mona-ai-intern database');
    } catch (error) {
      console.error('Error writing document: ', error);
    }
  }

  async updateAssessmentTest(docId: any, doc: any) {
    const collectionRef = this.app2.firestore.collection('assessmentTests')
    const docRef = collectionRef.doc(docId); // Reference to the specific document

    try {
      // Write a document to the 'assessmentTests' collection
      await docRef.update(doc);
      console.log(`Document with ID ${docId} updated in assessmentTests collection`);
    } catch (error) {
      console.error('Error writing document: ', error);
    }
  }

  getAssessmentTests(): firebase.firestore.CollectionReference {
    return this.app2.firestore.collection("assessmentTests");
  }

  getAssessmentTestDetail(id): firebase.firestore.DocumentReference {
    return this.app2.firestore.collection("assessmentTests").doc(id)
  }

  getDocumentById(docId: string) {
    return this.app2.doc(`assessmentTests/${docId}`).valueChanges();
  }

  getActivities(): firebase.firestore.CollectionReference {
    return this.app2.firestore.collection("activities");
  }

  getCalendar() {
    if (this.calendar != "") {
      return this.calendar
    } else {
      return "no calendar"
    }
  }

  getProjectId() {
    return this.db.projectId;
  }

  saveCompanyLogo(logo) {
    this.businessLogo = logo;
  }

  async connectDB(data) {
    if (data) {
      this.db = data.database;
      console.log("this.db", data);
      this.HostingURL = "https://" + this.db.projectId + ".firebaseapp.com";
      this.joinApiKey = data.joinapikey;
      this.calendar = data.calendar;
      this.businessName = data.name;
      this.customerAdminEmailAdress = data.email;
      this.cloudFunctionURL = data.cloudFunctionURL || "";
      this.cloudFunctionWaitingVideoURL = data.cloudFunctionWaitingVideoURL || "";
      this.customerNumber = data.kundennummer;
      this.accountId = data.account_id;
      console.log("this.accountId", this.accountId);
      const platformId = PLATFORM_ID;
      const settings = {};
      this.app2 = new AngularFirestore(this.db, 'CustomerDatabase', false, null, platformId, this.zone, settings);
      if (this.app2 != undefined) {
        if (this.accountId != "" && this.accountId != undefined) {
          this.app2.firestore = this.app2.firestore.collection("accounts").doc(this.accountId);
          console.log("app2 initialized");
        }
        console.log("app2 initialized");
        this.createdQuery = this.getApplicants()
          // .where('created', '>=', this.thirtyDaysAgo)
          .orderBy("created", "desc")
          .limit(727);
        // .limit(50)
      }
      return true
    } else {
      return false
    }
  }

  async checkIfUsersExists(userId) {
    try {
      const userRef = await this.app2.firestore.collection("users").doc(userId).get();
      if (userRef.exists) {
        console.log("exists");
        return true
      } else {
        console.log("Nope");
        return false
      }
    } catch (err) {
      console.log("err", err);
    }
  }

  createuserInsideUsers(uid) {
    this.app2.firestore.collection("users").doc(uid).set({
      credits: 999999 // TODO change later for production and upselling
    })
  }

  retreiveUserCreditsAllTheTimeUpToDate(callback: (credits: number) => void) {

    console.log("this.authservice", this.authService.returnAccountId());
    console.log("this.authservice", this.authService.returnUserId());

    let accountId = this.authService.returnAccountId();
    // let userId = this.authService.returnUserId();
    if (accountId != null && accountId != undefined && accountId != "") {

      // if(userId == this.authService.returnAccountId()) {
      //   // if admin
      //   return this.app2
      //   .firestore
      //   .onSnapshot((doc) => {
      //     if (doc.exists) {
      //       const userData = doc.data();
      //       const credits = userData?.credits ?? 0; // Assuming 'credits' is the field name
      //       callback(credits);
      //     } else {
      //       console.error("No such document!");
      //     }
      //   }, (error) => {
      //     console.error("Error retrieving user credits: ", error);
      //   });
      // } else {
      return this.app2.firestore
        .collection("users")
        .doc(this.authService.returnUserId())
        .onSnapshot((doc) => {
          if (doc.exists) {
            const userData = doc.data();
            const credits = userData?.credits ?? 0; // Assuming 'credits' is the field name
            callback(credits);
          } else {
            console.error("No such document!");
          }
        }, (error) => {
          console.error("Error retrieving user credits: ", error);
        });
      // }
    } else {
      return this.app2.firestore
        .collection("users")
        .doc(this.authService.returnUserId())
        .onSnapshot((doc) => {
          if (doc.exists) {
            const userData = doc.data();
            const credits = userData?.credits ?? 0; // Assuming 'credits' is the field name
            callback(credits);
          } else {
            console.error("No such document!");
          }
        }, (error) => {
          console.error("Error retrieving user credits: ", error);
        });
    }


  }


  returnCustomerDB() {
    return this.app2;
  }

  getApplicantMailings() {
    return this.app2.firestore.collection("applicantmailing");
  }

  returnBusinessLogo() {
    return this.businessLogo;
  }

  returnBusinessName() {
    return this.businessName || "";
  }

  returnCustomerAdminEmailAdress() {
    return this.customerAdminEmailAdress;
  }

  ApplicantEmailCorrespondence(item, email, vorlage, url, anrede) {
    return this.app2.firestore.collection("applicantmailing").add({
      customer: item,
      email: email,
      vorlage: vorlage,
      url: url,
      anrede: anrede,
      created: new Date(),
      createdGerman: (new Date()).toLocaleDateString("de-DE"),
      createdISO: (new Date()).toISOString(),
      createdISODate: (new Date()).toISOString().split("T")[0]
    })
  }

  CustomerEmailCorrespondence(email, candidate) {
    return this.app2.firestore.collection("customermailing").add({
      email: email,
      candidate: candidate,
      created: new Date(),
      createdGerman: (new Date()).toLocaleDateString("de-DE"),
      createdISO: (new Date()).toISOString(),
      createdISODate: (new Date()).toISOString().split("T")[0]
    })
  }

  getWorkflows(): firebase.firestore.CollectionReference {
    return this.app2.firestore.collection("workflows");
  }

  getWorkflowDetail(id): firebase.firestore.DocumentReference {
    return this.app2.firestore.collection("workflows").doc(id)
  }

  updateWorkflow(id, form) {
    console.log(form);
    console.log(id);
    return this.app2.firestore.collection("workflows").doc(id).update({
      name: form.name || "",
      appliesto: form.appliesto || "",
      whenthishappens: form.whenthishappens || "",
      dothis: form.dothis || "",
      id: form.id || "",
      receiver: form.receiver || "",
      calendlyLink: form.calendlyLink || "",
      when: form.when || "",
      receivers: form.receivers || [],
      updated: new Date() || "",
      updatedGerman: (new Date()).toLocaleDateString("de-DE") || "",
      updatedISO: (new Date()).toISOString() || "",
      // new ones
      workflowSendEmailSubject: form.workflowSendEmailSubject,
      workflowSendEmailMessage: form.workflowSendEmailMessage || "",
      workflowSendEmailTitle: form.workflowSendEmailTitle || "",
      emails: form.emails || [],
      emailText: form.emailText || "",
      smsCountryCode: form.smsCountryCode || "",
      smsPhoneNumbers: form.smsPhoneNumbers || "",
      //added phoneNumber and WhatsappNumber (smsPhoneNumbers when undefined should also be [], maybe change in the future) (31.10.2024)
      callPhoneNumbers: form.callPhoneNumbers || [],
      whatsAppPhoneNumbers: form.whatsAppPhoneNumbers || [],
      smsText: form.smsText || "",
      information: form.information || [],
      dynamicAttachment: form.dynamicAttachment || [],
      customTime: form.customTime || {},
      attachment: form.attachment || "",
      attachmentURL: form.attachmentURL || "",
      document: form.document || "",
      documentURL: form.documentURL || "",
      prompt: form.prompt || "",
      agent: form.agent || {},
      // superior changes
      lastChosenWorkflow: form.lastChosenWorkflow || "",
      priorityOfWorkflow: form.priorityOfWorkflow || "",
      subWorkflows: form.subWorkflows || [],
      lastChosenWorkflowMatching: form.lastChosenWorkflowMatching || "",
      reminder: form.reminder || {},
    });
  }

  getJoinApiKey() {
    if (this.joinApiKey) {
      return this.joinApiKey
    } else {
      return "noApiKey"
    }
  }

  getHostingURL() {
    if (this.HostingURL) {
      return this.HostingURL
    } else {
      return "noHostingURL"
    }
  }

  getUserDB() {
    return this.app2;
  }

  getJobOffers(): firebase.firestore.CollectionReference {
    return this.app2.firestore.collection(FirebaseConstants.jobOffers);
  }

  getJobOffersProcessed(): Promise<any> {
    console.log("getJobOffersProcessed");
    const start = performance.now();
    return new Promise(async (resolve, reject) => {
      let allJobOffers = [];
      this.jobOfferSubscription = this.app2.firestore.collection(FirebaseConstants.jobOffers).onSnapshot(async (snap: { docs: any; }) => {
        console.log("getJobOffersProcessed inside snapshot");
        var docs = snap.docs;
        var docLength = docs.length;
        if (docLength > 0) {
          docs.forEach((doc) => {
            var data = doc.data();
            data.id = doc.id;
            allJobOffers.push(data);
          });
          const end = performance.now();
          console.log("Time taken to fetch jobOffers: ", (end - start) / 1000, " seconds");
          console.log("--- Fetched jobOffers:: ", allJobOffers);
          //removing snapshot
          this.jobOfferSubscription();
          resolve(allJobOffers);
        } else {
          //removing snapshot
          this.jobOfferSubscription();
          resolve([]);
        }
      }, (error) => {
        console.log(error);
        reject(error);
      });
    })
  }

  getDeletedJobOffers(): firebase.firestore.CollectionReference {
    return this.app2.firestore.collection(FirebaseConstants.deletedJobOffers);
  }
  getDeletedJobOffersDetail(id): firebase.firestore.DocumentReference {
    return this.app2.firestore.collection(FirebaseConstants.deletedJobOffers).doc(id)
  }

  getJobOffersDetail(id): firebase.firestore.DocumentReference {
    return this.app2.firestore.collection(FirebaseConstants.jobOffers).doc(id)
  }

  getJOINJobOffers() {
    return CustomerdatabaseService.joinJobOffers;
  }

  setJOINJobOffer(jobOffer) {
    var isSet = false;
    var id = jobOffer.id;
    CustomerdatabaseService.joinJobOffers.forEach(snap => {
      var snapId = snap.id;
      if (id == snapId) {
        isSet = true;
      }
    })
    console.log(isSet);
    if (!isSet) {
      CustomerdatabaseService.joinJobOffers.push(jobOffer);
    }
  }

  getCodingApplicants(): firebase.firestore.CollectionReference {
    return this.app2.firestore.collection(FirebaseConstants.codingapplicants);
  }

  getCoderbyteApplicants(): firebase.firestore.CollectionReference {
    return this.app2.firestore.collection(FirebaseConstants.coderbyteapplicants);
  }

  getApplicantDetail(id): firebase.firestore.DocumentReference {
    return this.app2.firestore.collection(FirebaseConstants.applicants).doc(id)
  }

  getApplicantFiles(id) {
    return this.getApplicantDetail(id).get().then((snap) => {
      var data = snap.data();
      if (data) {
        if (data.files) {
          if (data.files.length > 0) {
            return data.files
          }
        }
      }
    })
  }

  saveApplicant(bewerber) {
    this.JoinBewerber = bewerber;
  }

  deleteApplicant(applicantId) {
    return this.app2.firestore.collection(FirebaseConstants.applicants).doc(applicantId).delete();
  }

  /**
   * Add applicant to deleted applicants collection in Firestore.
   * @param applicantToDelete 
   */
  async addToDeletedApplicants(applicantToDelete) {
    let applicantData = await this.getApplicantDetail2(applicantToDelete).get();
    if (applicantData) {
      let data = applicantData.data();
      if (data) {
        if (data.cv_embedding) {
          delete data.cv_embedding;
        }
        await this.app2.firestore.collection(FirebaseConstants.deletedApplicants).add(data);
        console.log("Applicant added to deletedApplicants collection :: ", applicantToDelete);
      }
    }
  }

  getJoinApplicant() {
    return this.JoinBewerber
  }

  async archiveApplicant(id, archived) {
    return this.app2.firestore.collection("applicants").doc(id).update({
      archived: archived
    });
  }

  getApplicantDetail2(id): firebase.firestore.DocumentReference {
    return this.app2.firestore.collection("applicants").doc(id)
  }

  getProfile(): firebase.firestore.CollectionReference {
    return this.app2.firestore.collection(FirebaseConstants.branding);
  }

  async getProfileLanguage() {
    return await this.getProfile().get().then((snap) => {
      var docs = snap.docs;
      var docLength = docs.length;
      if (docLength > 0) {
        return docs[0].data().platformLanguage;
      } else {
        return false;
      }
    });
  }

  deleteField(id) {
    return this.app2.firestore.collection(FirebaseConstants.applicants).doc(id).update({
      readyForExport: this.app2.firestore.FieldValue.delete()
    });
  }

  createProfile(data) {
    return this.app2.firestore.collection(FirebaseConstants.branding).add({
      firmenname: data.firmenname || "",
      webseite: data.webseite || "",
      companyLogo: data.companyLogo || "",
      colorCode: data.colorCode || "",
      email: data.email || "",
      password: data.password || "",
      created: new Date(),
      status: "aktiv",
      createdISO: new Date().toISOString(),
      createdISODate: new Date().toISOString().split('T')[0],
      createdGerman: new Date().toLocaleString('de-DE'),
      emailText: data.emailText || "",
      fallbackInterviewId: data.fallbackInterviewId || "",
      interviewFinishedURL: data.interviewFinishedURL || "",
      euMatching: data.euMatching || false,
      minimumMatchingResult: data.minimumMatchingResult || 0,
      chosenCriteria: data.chosenCriteria || [],
      mustHaves: data.mustHaves || [],
      personalizedMessage: data.personalizedMessage,
      dataProtection: data.dataProtection || ""
    })
  }

  getAPIData() {
    return this.getProfile().get().then((snap) => {
      var docs = snap.docs;
      var docLength = docs.length;
      if (docLength > 0) {
        return docs[0].data();
      } else {
        return false;
      }
    })
  }

  saveZvooveAPIKeys(data) {
    return this.getProfile().get().then((snap) => {
      let docs = snap.docs;
      let docLength = docs.length;
      if (docLength > 0) {
        var id = docs[0].id;
        return this.app2.firestore.collection(FirebaseConstants.branding).doc(id).update({
          zvoove: data.zvoove,
          zvooveBewerberApiKey: data.zvooveBewerberApiKey || "",
          zvooveBewerberApiLink: data.zvooveBewerberApiLink || "",
          zvooveStellenApiKey: data.zvooveStellenApiKey || "",
          zvooveStellenApiLink: data.zvooveStellenApiLink || "",
          zvoooveNextLevelApiKey: data.zvoooveNextLevelApiKey || "",
          zvooveNextLevelApiLink: data.zvooveNextLevelApiLink || "",
          zvooveBewerberApiLinkById: data.zvooveBewerberApiLinkById || "",
          zvooveCreateBewerberApiLink: data.zvooveCreateBewerberApiLink || "",
          username: data.username || "",
          password: data.password || "",
          AIMatchingType: data.AIMatchingType || "",
          updated: new Date(),
          status: "aktiv"
        })
      } else {
        return false;
      }
    })
  }

  async saveIntegrationData(app: any) {
    try {
      // Extract appId from app
      const appId = app.appId;

      // Collect data directly from app
      const data: any = {};

      // Since the data is stored directly in app, extract the keys
      app.fields.forEach((field: any) => {
        const key = field.key;
        data[key] = app[key]; // Get the value from app directly
      });

      console.log("Data to save: ", data);

      // Retrieve the branding document ID
      const snap = await this.getProfile().get();
      const docs = snap.docs;

      if (docs.length > 0) {
        const id = docs[0].id;

        // Prepare the update data
        const updateData: any = {
          updated: new Date(),
          status: "aktiv",
          ...data, // Include all collected fields
        };

        // Update the branding document
        await this.app2.firestore
          .collection(FirebaseConstants.branding)
          .doc(id)
          .update(updateData);

        console.log(`Data for ${appId} saved successfully.`);
      } else {
        console.error("Branding document not found.");
      }
    } catch (error) {
      console.error(`Error saving data for ${app.appId}:`, error);
      throw error; // Rethrow to handle it in integrations.ts
    }
  }

  async saveIntegrationDataAccount(app: any, accountId: string, userId: string) {
    try {
      // Extract appId from app
      const appId = app.appId;

      // Collect data directly from app
      const data: any = {};

      // Since the data is stored directly in app, extract the keys
      app.fields.forEach((field: any) => {
        const key = field.key;
        data[key] = app[key]; // Get the value from app directly
      });

      console.log("Data to save: ", data);

      // Retrieve the branding document ID
      const snap = await this.getProfile().get();
      const docs = snap.docs;

      if (docs.length > 0) {
        const id = docs[0].id;

        // Prepare the update data
        const updateData: any = {
          updated: new Date(),
          status: "aktiv",
          ...data, // Include all collected fields
        };

        // Update the branding document
        await this.app2.firestore
          .collection(FirebaseConstants.branding)
          .doc(id)
          .update(updateData);

        console.log(`Data for ${appId} saved successfully.`);
      } else {
        console.error("Branding document not found.");
      }
    } catch (error) {
      console.error(`Error saving data for ${app.appId}:`, error);
      throw error; // Rethrow to handle it in integrations.ts
    }
  }

  saveTalent360APIKeys(data) {
    return this.getProfile().get().then((snap) => {
      let docs = snap.docs;
      let docLength = docs.length;
      if (docLength > 0) {
        var id = docs[0].id;
        return this.app2.firestore.collection(FirebaseConstants.branding).doc(id).update({
          talent360: data.talent360,
          talentClientId: data.talentClientId || "",
          talentClientSecret: data.talentClientSecret || "",
          talentAPIBaseUrl: data.talentAPIBaseUrl || "",
          talentAccessTokenUrl: data.talentAccessTokenUrl || "",
          talentPublicAudience: data.talentPublicAudience || "",
          updated: new Date(),
          status: "aktiv"
        })
      } else {
        return false;
      }
    })
  }

  saveIndexAPIKeys(data) {
    return this.getProfile().get().then((snap) => {
      let docs = snap.docs;
      let docLength = docs.length;
      if (docLength > 0) {
        var id = docs[0].id;
        return this.app2.firestore.collection(FirebaseConstants.branding).doc(id).update({
          index: data.index,
          indexLogin: data.indexLogin || "",
          indexPassword: data.indexPassword || "",
          updated: new Date(),
        })
      } else {
        return false;
      }
    })
  }

  saveZapierAPIKeys(data) {
    return this.getProfile().get().then((snap) => {
      let docs = snap.docs;
      let docLength = docs.length;
      if (docLength > 0) {
        var id = docs[0].id;
        return this.app2.firestore.collection(FirebaseConstants.branding).doc(id).update({
          zapier: data.zapier,
          updated: new Date(),
          status: "aktiv"
        })
      } else {
        return false;
      }
    })
  }

  saveCustomIntegrationAPIKeys(data) {
    return this.getProfile().get().then((snap) => {
      let docs = snap.docs;
      let docLength = docs.length;
      if (docLength > 0) {
        var id = docs[0].id;
        return this.app2.firestore.collection(FirebaseConstants.branding).doc(id).update({
          customIntegration: data.customIntegration,
          updated: new Date(),
          status: "aktiv"
        })
      } else {
        return false;
      }
    })
  }

  saveTalentionAPIKeys(data) {
    return this.getProfile().get().then((snap) => {
      let docs = snap.docs;
      let docLength = docs.length;
      if (docLength > 0) {
        var id = docs[0].id;
        return this.app2.firestore.collection(FirebaseConstants.branding).doc(id).update({
          talention: data.talention,
          talentionClientId: data.talentionClientId || "",
          talentionClientSecret: data.talentionClientSecret || "",
          talentionAccountId: data.talentionAccountId || "",
          talentionAccountOption: data.talentionAccountOption || "",
          talentionAccountOptionBranches: data.talentionAccountOptionBranches || "",
          talentionMonaStatusEingang: data.talentionMonaStatusEingang || "",
          talentionMonaStatusImInterview: data.talentionMonaStatusImInterview || "",
          talentionMonaStatusInterviewAbgeschlossen: data.talentionMonaStatusInterviewAbgeschlossen || "",
          talentionMonaStatusProfilErstellt: data.talentionMonaStatusProfilErstellt || "",
          talentionMonaStatusProfilVersendet: data.talentionMonaStatusProfilVersendet || "",
          AIMatchingType: data.AIMatchingType || "",
          updated: new Date(),
          status: "aktiv"
        })
      } else {
        return false;
      }
    })
  }

  saveCompleetAPIKeys(data) {
    return this.getProfile().get().then((snap) => {
      let docs = snap.docs;
      let docLength = docs.length;
      if (docLength > 0) {
        var id = docs[0].id;
        return this.app2.firestore.collection(FirebaseConstants.branding).doc(id).update({
          compleet: data.compleet || true,
          compleetAPIKeys: data.compleetAPIKeys || "",
          initiativRow: data.initiativRow || "",
          initiativApiKey: data.initiativApiKey || "",
          updated: new Date(),
          AIMatchingType: data.AIMatchingType || "",
          status: "aktiv"
        })
      } else {
        return false;
      }
    })
  }

  AddIndexAnzeigen(data) {
    if (data['job-id']) {
      return this.app2.firestore.collection("indexstellenanzeigen").add({
        contantName: data['ad-contact-person'],
        contactEmail: data['ad-contact-person-email'],
        jobOfferText: data['ad-text'],
        companyName: data['company'],
        jobId: data['job-id'],
        lastFound: data['last-found'],
        location: data['location'],
        output: data['output'],
        source: data['source'],
        adprice: data['ad-price'],
        language: data['ad-language'],
        street: data['street'],
        zip: data['zip'],
        city: data['city'],
        url: data['doc-link-txt'],
        streetOnly: data['street-only'],
        houseNumber: data['house-number'],
        created: new Date(),
        status: "aktiv",
        createdISO: new Date().toISOString(),
        createdISODate: new Date().toISOString().split('T')[0],
      })
    }
  }

  updateApplicantCVQualifications(id, data) {
    return this.app2.firestore.collection(FirebaseConstants.applicants).doc(id).update({
      cvQualifications: data,
      cvAnalyzed: true,
      CVcreated: new Date(),
      status: "aktiv",
      CVcreatedISO: new Date().toISOString(),
      CVcreatedISODate: new Date().toISOString().split('T')[0],
      CVcreatedGerman: new Date().toLocaleString('de-DE')
    })
  }

  updateIndexAnzeigen(id, data) {
    return this.app2.firestore.collection("indexstellenanzeigen").doc(id).update({
      contantName: data['ad-contact-person'],
      contactEmail: data['ad-contact-person-email'],
      jobOfferText: data['ad-text'],
      companyName: data['company'],
      jobId: data['job-id'],
      lastFound: data['last-found'],
      location: data['location'],
      output: data['output'],
      source: data['source'],
      adprice: data['ad-price'],
      language: data['ad-language'],
      street: data['street'],
      zip: data['zip'],
      city: data['city'],
      streetOnly: data['street-only'],
      houseNumber: data['house-number'],
      url: data['doc-link-txt'],
      created: new Date(),
      status: "aktiv",
      createdISO: new Date().toISOString(),
      createdISODate: new Date().toISOString().split('T')[0],
    })
  }

  updateIndexAnzeigenAI(id, data) {
    return this.app2.firestore.collection("indexstellenanzeigen").doc(id).update({
      jobOfferFeedback: data,
    })
  }

  getIndexAnzeigen(): firebase.firestore.CollectionReference {
    return this.app2.firestore.collection("indexstellenanzeigen");
  }

  getZvooveJobsApiKey(): Promise<string> {
    return this.getProfile().get().then((snap) => {
      let docs = snap.docs;
      let docLength = docs.length;
      if (docLength > 0) {
        return docs[0].data().zvooveStellenApiKey;
      } else {
        return "";
      }
    })
  }

  getZvooveJobsApiLink(): Promise<string> {
    return this.getProfile().get().then((snap) => {
      var docs = snap.docs;
      var docLength = docs.length;
      if (docLength > 0) {
        return docs[0].data().zvooveStellenApiLink;
      } else {
        return "";
      }
    })
  }

  getZvooveBewerberApiKey(): Promise<string> {
    return this.getProfile().get().then((snap) => {
      var docs = snap.docs;
      var docLength = docs.length;
      if (docLength > 0) {
        return docs[0].data().zvooveBewerberApiKey;
      } else {
        return "";
      }
    })
  }

  getZvooveBewerberApiLinkById(): Promise<string> {
    return this.getProfile().get().then((snap) => {
      var docs = snap.docs;
      var docLength = docs.length;
      if (docLength > 0) {
        return docs[0].data().zvooveBewerberApiLinkById;
      } else {
        return "";
      }
    })
  }

  getZvooveBewerberApiLink(): Promise<string> {
    return this.getProfile().get().then((snap) => {
      var docs = snap.docs;
      var docLength = docs.length;
      if (docLength > 0) {
        return docs[0].data().zvooveBewerberApiLink;
      } else {
        return "";
      }
    })
  }

  getZvooveCreateBewerberApiLink(): Promise<string> {
    return this.getProfile().get().then((snap) => {
      var docs = snap.docs;
      var docLength = docs.length;
      if (docLength > 0) {
        return docs[0].data().zvooveCreateBewerberApiLink;
      } else {
        return "";
      }
    })
  }

  getZvooveMandantUuID(): Promise<string> {
    return this.getProfile().get().then((snap) => {
      var docs = snap.docs;
      var docLength = docs.length;
      if (docLength > 0) {
        return docs[0].data().zvooveMandantUuID;
      } else {
        return "";
      }
    })
  }

  updateFallbackJobOffer(id) {
    this.getJobOffersDetail(id).update({
      isFallback: true
    })
    this.getJobOffers().get().then((snap) => {
      snap.forEach((doc) => {
        if (doc.id != id) {
          this.getJobOffersDetail(doc.id).update({
            isFallback: false
          })
        }
      })
    })
  }

  retreiveMONAIMAPAccounts() {
    return this.getProfile().get().then((snap) => {
      var docs = snap.docs;
      var docLength = docs.length;
      if (docLength > 0) {
        return docs[0].data().imapArray;
      } else {
        return [];
      }
    })
  }

  retreiveMONASMTPAccounts() {
    return this.getProfile().get().then((snap) => {
      var docs = snap.docs;
      var docLength = docs.length;
      if (docLength > 0) {
        return docs[0].data().smtpArray;
      } else {
        return [];
      }
    })
  }

  saveMONAIMAPAccountWithAttachedWorkflow(email, workflow) {
    return this.getProfile().get().then((snap) => {
      var docs = snap.docs;
      var docLength = docs.length;
      let imapArray = [];
      if (docLength > 0) {
        var id = docs[0].id;
        if (docs[0].data().imapArray) {
          imapArray = docs[0].data().imapArray;
        }
        console.log("imapArray: ", imapArray);

        // check if email already exists
        let emailExists = false;
        imapArray.forEach((item) => {
          if (item.email == email.email) {
            emailExists = true;
          }
        })

        if (emailExists) {
          // only update the workflows inside the array element, not the array itself
          imapArray.forEach((item) => {
            if (item.email == email.email) {
              item.workflow = workflow;
            }
          });
          return this.app2.firestore.collection(FirebaseConstants.branding).doc(id).update({
            imapArray: imapArray
          })
        }
        if (imapArray.length > 0) {
          let item = {
            email: email.email,
            password: email.password,
            host: email.host || "mail.mona-ai.online",
            tls: email.tls || true,
            port: email.port || 993,
            timeRange: email.timeRange || "1day",
            authTimeout: email.authTimeout || 30000,
            workflow: workflow
          }
          imapArray.push(item);
        } else {
          imapArray = [{
            email: email.email,
            password: email.password,
            host: email.host || "mail.mona-ai.online",
            tls: email.tls || true,
            port: email.port || 993,
            timeRange: email.timeRange || "1day",
            authTimeout: email.authTimeout || 30000,
            workflow: workflow
          }]
        }
        console.log("imapArray: ", imapArray);
        return this.app2.firestore.collection(FirebaseConstants.branding).doc(id).update({
          imapArray: imapArray
        })
      } else {
        return false;
      }
    })
  }

  saveMONASMTPAccountWithAttachedWorkflow(email, workflow) {
    return this.getProfile().get().then((snap) => {
      var docs = snap.docs;
      var docLength = docs.length;
      let smtpArray = [];
      if (docLength > 0) {
        var id = docs[0].id;
        if (docs[0].data().smtpArray) {
          smtpArray = docs[0].data().smtpArray;
        }
        console.log("smtpArray: ", smtpArray);

        // check if email already exists
        let emailExists = false;
        smtpArray.forEach((item) => {
          if (item.email == email.email) {
            emailExists = true;
          }
        })

        if (emailExists) {
          // only update the workflows inside the array element, not the array itself
          smtpArray.forEach((item) => {
            if (item.email == email.email) {
              item.workflow = workflow;
            }
          });
          return this.app2.firestore.collection(FirebaseConstants.branding).doc(id).update({
            smtpArray: smtpArray
          })
        }
        if (smtpArray.length > 0) {
          let item = {
            email: email.email,
            password: email.password,
            host: email.host || "mail.mona-ai.online",
            tls: email.tls || true,
            port: email.port || 993,
            timeRange: email.timeRange || "1day",
            authTimeout: email.authTimeout || 30000,
            workflow: workflow
          }
          smtpArray.push(item);
        } else {
          smtpArray = [{
            email: email.email,
            password: email.password,
            host: email.host || "mail.mona-ai.online",
            tls: email.tls || true,
            port: email.port || 993,
            timeRange: email.timeRange || "1day",
            authTimeout: email.authTimeout || 30000,
            workflow: workflow
          }]
        }
        console.log("smtpArray: ", smtpArray);
        return this.app2.firestore.collection(FirebaseConstants.branding).doc(id).update({
          smtpArray: smtpArray
        })
      } else {
        return false;
      }
    })
  }

  saveEmailCredentials(data) {
    return this.getProfile().get().then((snap) => {
      var docs = snap.docs;
      var docLength = docs.length;
      if (docLength > 0) {
        var id = docs[0].id;
        return this.app2.firestore.collection(FirebaseConstants.branding).doc(id).update({
          email: data.email || "",
          password: data.password || "",
          updated: new Date(),
          status: "aktiv"
        })
      } else {
        return false;
      }
    })
  }

  deleteMONAIMAPAccount(email) {
    return this.getProfile().get().then((snap) => {
      var docs = snap.docs;
      var docLength = docs.length;
      if (docLength > 0) {
        var id = docs[0].id;
        var imapArray = docs[0].data().imapArray;
        var newIMAPArray = imapArray.filter((item) => {
          return item.email != email;
        })
        return this.app2.firestore.collection(FirebaseConstants.branding).doc(id).update({
          imapArray: newIMAPArray
        })
      } else {
        return false;
      }
    })
  }

  deleteMONASMTPAccount(email) {
    return this.getProfile().get().then((snap) => {
      var docs = snap.docs;
      var docLength = docs.length;
      if (docLength > 0) {
        var id = docs[0].id;
        var smtpArray = docs[0].data().smtpArray;
        var newSMTPArray = smtpArray.filter((item) => {
          return item.email != email;
        })
        return this.app2.firestore.collection(FirebaseConstants.branding).doc(id).update({
          smtpArray: newSMTPArray
        })
      } else {
        return false;
      }
    })
  }

  createProfileEmail(email, password, host?, tls?, port?, timeRange?, authTimeout?, type?) {
    if (type == "imap") {
      return this.getProfile().get().then((snap) => {
        var docs = snap.docs;
        var docLength = docs.length;
        let imapArray = [];
        if (docLength > 0) {
          var id = docs[0].id;
          if (docs[0].data().imapArray) {
            imapArray = docs[0].data().imapArray;
          }
          // check if email already exists
          let emailExists = false;
          imapArray.forEach((item) => {
            if (item.email == email) {
              emailExists = true;
            }
          })

          if (emailExists) {
            return false;
          }

          if (imapArray.length > 0) {
            let item = {
              email: email,
              password: password,
              host: host || "mail.mona-ai.online",
              tls: tls || true,
              port: port || 993,
              timeRange: timeRange || "1day",
              authTimeout: authTimeout || 30000
            }
            imapArray.push(item);
          } else {
            imapArray = [{
              email: email,
              password: password,
              host: host || "mail.mona-ai.online",
              tls: tls || true,
              port: port || 993,
              timeRange: timeRange || "1day",
              authTimeout: authTimeout || 30000
            }]
          }
          console.log("imapArray: ", imapArray);
          return this.app2.firestore.collection(FirebaseConstants.branding).doc(id).update({
            imapArray: imapArray
          })
        } else {
          return false;
        }
      })
    } else if (type == "smtp") {
      return this.getProfile().get().then((snap) => {
        var docs = snap.docs;
        var docLength = docs.length;
        if (docLength > 0) {
          var id = docs[0].id;
          return this.app2.firestore.collection(FirebaseConstants.branding).doc(id).update({
            smtpEmail: email || "",
            smtpPassword: password || "",
            smtpHost: host || "mail.mona-ai.online",
            smtpTls: tls || true,
            smtpPort: port || 993,
            smtpTimeRange: timeRange || "1day",
            smtpAuthTimeout: authTimeout || 30000
          })
        } else {
          return false;
        }
      })
    }
  }

  updateProfileSMTP(email, password, host?, tls?, port?, timeRange?, authTimeout?) {
    // get profile and add the email into the array of smtpArray
    return this.getProfile().get().then((snap) => {
      var docs = snap.docs;
      var docLength = docs.length;
      let smtpArray = [];
      if (docLength > 0) {
        var id = docs[0].id;
        if (docs[0].data().smtpArray) {
          smtpArray = docs[0].data().smtpArray;
        }
        // check if email already exists
        let emailExists = false;
        let passwordExists = false;
        smtpArray.forEach((item) => {
          if (item.email == email) {
            emailExists = true;
          }
          if (item.password == password) {
            passwordExists = true;
          }
        })

        if (emailExists && passwordExists) {
          return false;
        }

        if (smtpArray.length > 0) {
          let item = {
            email: email,
            password: password,
            host: host || "mail.mona-ai.online",
            tls: tls || true,
            port: port || 993,
            timeRange: timeRange || "1day",
            authTimeout: authTimeout || 30000
          }
          smtpArray.push(item);
        } else {
          smtpArray = [{
            email: email,
            password: password,
            host: host || "mail.mona-ai.online",
            tls: tls || true,
            port: port || 993,
            timeRange: timeRange || "1day",
            authTimeout: authTimeout || 30000
          }]
        }
        console.log("smtpArray: ", smtpArray);
        return this.app2.firestore.collection(FirebaseConstants.branding).doc(id).update({
          smtpArray: smtpArray
        })
      } else {
        return false;
      }
    })
  }

  updateProfileIMAP(email, password, host?, tls?, port?, timeRange?, authTimeout?) {
    // get profile and add the email into the array of imaparray
    return this.getProfile().get().then((snap) => {
      var docs = snap.docs;
      var docLength = docs.length;
      let imapArray = [];
      if (docLength > 0) {
        var id = docs[0].id;
        if (docs[0].data().imapArray) {
          imapArray = docs[0].data().imapArray;
        }
        // check if email already exists
        let emailExists = false;
        let passwordExists = false;
        imapArray.forEach((item) => {
          if (item.email == email) {
            emailExists = true;
          }
          if (item.password == password) {
            passwordExists = true;
          }
        })

        if (emailExists && passwordExists) {
          return false;
        }

        if (imapArray.length > 0) {
          let item = {
            email: email,
            password: password,
            host: host || "mail.mona-ai.online",
            tls: tls || true,
            port: port || 993,
            timeRange: timeRange || "1day",
            authTimeout: authTimeout || 30000
          }
          imapArray.push(item);
        } else {
          imapArray = [{
            email: email,
            password: password,
            host: host || "mail.mona-ai.online",
            tls: tls || true,
            port: port || 993,
            timeRange: timeRange || "1day",
            authTimeout: authTimeout || 30000
          }]
        }
        console.log("imapArray: ", imapArray);
        return this.app2.firestore.collection(FirebaseConstants.branding).doc(id).update({
          imapArray: imapArray
        })
      } else {
        return false;
      }
    })
  }

  updateProfile(data) {
    let newName = `${new Date().getTime()}-companyLogo.png`;
    let storageRef: AngularFireStorageReference = this.customerStorage.ref(`/company/${newName}`);

    // Check if companyLogo is filled
    if (data.companyLogo != null && data.companyLogo !== '' && data.companyLogo !== undefined) {
      const storageObs = from(storageRef.putString(data.companyLogo, 'base64', { contentType: 'image/png' }));

      return storageObs.pipe(
        switchMap(obj => obj.ref.getDownloadURL()),
        switchMap(url => {
          console.log('my url: ', url);
          return from(this.getProfile().get()).pipe(
            switchMap((snap) => {
              const docs = snap.docs;
              if (docs.length > 0) {
                return from(this.getProfile().doc(docs[0].id).update({
                  firmenname: data.firmenname || "",
                  webseite: data.webseite || "",
                  companyLogo: data.companyLogo || "",
                  colorCode: data.colorCode || "",
                  email: data.email || "",
                  created: new Date(),
                  status: "aktiv",
                  createdISO: new Date().toISOString(),
                  createdISODate: new Date().toISOString().split('T')[0],
                  createdGerman: new Date().toLocaleString('de-DE'),
                  standorte: data.standorte || [],
                  zvooveBewerberApiKey: data.zvooveBewerberApiKey || "",
                  zvooveBewerberApiLink: data.zvooveBewerberApiLink || "",
                  zvooveStellenApiKey: data.zvooveStellenApiKey || "",
                  zvooveStellenApiLink: data.zvooveStellenApiLink || "",
                  zvooveBewerberApiLinkById: data.zvooveBewerberApiLinkById || "",
                  zvooveCreateBewerberApiLink: data.zvooveCreateBewerberApiLink || "",
                  zvooveMandantUuID: data.zvooveMandantUuID || "",
                  fallbackInterviewId: data.fallbackInterviewId || "",
                  interviewFinishedURL: data.interviewFinishedURL || "",
                  chosenCriteria: data.chosenCriteria || [],
                  euMatching: data.euMatching || false,
                  minimumMatchingResult: data.minimumMatchingResult || 0,
                  mustHaves: data.mustHaves || [],
                  isZvoove: data.isZvoove,
                  emailText: data.emailText || "",
                  smsText: data.smsText || "",
                  autoTranslateInviteMessagesBool: data.autoTranslateInviteMessagesBool || false,
                  betreffText: data.betreffText || "",
                  personalizedMessage: data.personalizedMessage || "",
                  dataProtection: data.dataProtection || "",
                  platformLanguage: data.platformLanguage || "",
                  platformMatchingType: data.platformMatchingType || {},
                  platformMatchingTypeSpecific: data.platformMatchingTypeSpecific || {}
                }));
              } else {
                return from(this.createProfile(data));
              }
            })
          );
        })
      );

    } else {
      // If company logo is NOT filled, still return an Observable
      return from(this.getProfile().get()).pipe(
        switchMap((snap) => {
          const docs = snap.docs;
          if (docs.length > 0) {
            return from(this.app2.firestore.collection(FirebaseConstants.branding)
              .doc(docs[0].id)
              .update({
                firmenname: data.firmenname || "",
                webseite: data.webseite || "",
                companyLogo: data.companyLogo || "",
                colorCode: data.colorCode || "",
                email: data.email || "",
                created: new Date(),
                status: "aktiv",
                createdISO: new Date().toISOString(),
                createdISODate: new Date().toISOString().split('T')[0],
                createdGerman: new Date().toLocaleString('de-DE'),
                standorte: data.standorte || [],
                zvooveBewerberApiKey: data.zvooveBewerberApiKey || "",
                zvooveBewerberApiLink: data.zvooveBewerberApiLink || "",
                zvooveStellenApiKey: data.zvooveStellenApiKey || "",
                zvooveStellenApiLink: data.zvooveStellenApiLink || "",
                zvooveBewerberApiLinkById: data.zvooveBewerberApiLinkById || "",
                zvooveCreateBewerberApiLink: data.zvooveCreateBewerberApiLink || "",
                zvooveMandantUuID: data.zvooveMandantUuID || "",
                fallbackInterviewId: data.fallbackInterviewId || "",
                interviewFinishedURL: data.interviewFinishedURL || "",
                chosenCriteria: data.chosenCriteria || [],
                euMatching: data.euMatching || false,
                minimumMatchingResult: data.minimumMatchingResult || 0,
                mustHaves: data.mustHaves || [],
                isZvoove: data.isZvoove,
                emailText: data.emailText || "",
                smsText: data.smsText || "",
                autoTranslateInviteMessagesBool: data.autoTranslateInviteMessagesBool || false,
                betreffText: data.betreffText || "",
                personalizedMessage: data.personalizedMessage || "",
                dataProtection: data.dataProtection || "",
                platformLanguage: data.platformLanguage || "",
                platformMatchingType: data.platformMatchingType || {},
                platformMatchingTypeSpecific: data.platformMatchingTypeSpecific || {}
              })
            );
          } else {
            return from(this.createProfile(data));
          }
        })
      );
    }
  }
  createaApplicantProfile(form) {
    return this.app2.firestore.collection("applicantProfile").add({
      form: form,
      email: form.email,
      vorname: form.vorname || "",
      language: form.language,
      nachname: form.nachname || "",
      jobTitle: form.jobTitle || "",
      jobpost: form.jobTitle || "",
      codingScore: form.codingScore || "",
      codingTitle: form.challengeTitle || "",
      codingChallenge: form.codingChallenge || { checked: false, description: "", language: "", level: "", name: "", type: "", url: "" },
      bewerberID: form.id,
      created: new Date(),
      status: "aktiv",
      createdISO: new Date().toISOString(),
      createdISODate: new Date().toISOString().split('T')[0],
      createdGerman: new Date().toLocaleString('de-DE')
    })
  }

  updateApplicantProfile(id, form) {
    return this.app2.firestore.collection("applicantProfile").doc(id).update({
      form: form
    })
  }

  addApplicant(applicant) {
    return this.app2.firestore.collection(FirebaseConstants.applicants).add(applicant);
  }


  getEmailApplicants() {
    return this.app2.firestore.collection(FirebaseConstants.emailapplicants);
  }

  createZvooveApplicant(data) {
    this.getEmailApplicants().where("uid", "==", data.uid).get().then(async (snap) => {
      if (snap.docs.length == 0) {
        const apiKey = await this.getZvooveBewerberApiKey();
        const apiLink = await this.getZvooveBewerberApiLinkById();
        let body = {
          apiKey: apiKey,
          apiLink: apiLink,
          applicantId: data.uid
        }
        return this.http.post("https://us-central1-mona-ai-pwa-firebase.cloudfunctions.net/zvoove-applicants-by-id", body).subscribe((res: any) => {
          console.log(res);
          this.getEmailApplicants().add({
            created: new Date(),
            email: data.email || "",
            vorname: data.name || "",
            nachname: data.surename || "",
            telefonnummer: data.mobile || data.phone || "",
            uid: data.uid || "",
            quelle: "zvoove",
            jobUid: res.Bewerbungen.length > 0 ? res.Bewerbungen[0].Stelle.ObjectUuid : "",
            isInZvoove: true,
          })

        })
      }
    })
  }

  getReports() {
    return this.app2.firestore.collection("reporting");
  }

  returnChatHistory() {
    return this.app2.firestore.collection("MONARetrieval01")
  }

  addMONAChatMessage(message) {
    let userId = this.authService.returnUserId() || "";
    let accountId = this.authService.returnAccountId() || "";
    let db_level = "sub";
    if (accountId == "") {
      db_level = "top"
    }
    console.log("message", message);
    console.log("userId", userId);
    console.log("accountId", accountId);
    return this.app2.firestore.collection("MONARetrieval01").add({
      prompt: message.content || "",
      sender: message.sender,
      session_id: message.session_id,
      user_input: message.user_input,
      document_link: message.document_link,
      document_name: message.document_name,
      allowed_tools: message.allowed_tools,
      current_date: message.current_date,
      description: message.description,
      language_code: message.language_code,
      user_id: userId,
      db_level: db_level,
      account_id: accountId,
      created: new Date(),
      createTime: new Date(),
      createdISO: new Date().toISOString(),
    })
  }

  addFormattingAIBefore(message) {
    return this.app2.firestore.collection("MONARetrieval01").add({
      prompt: message.content || "",
      sender: message.sender,
      tool: "",
      created: new Date(),
      createTime: new Date(),
      createdISO: new Date().toISOString(),
    })
  }

  deleteMONAChatMessage(messageid) {
    return this.app2.firestore.collection("MONARetrieval01").delete(messageid)
  }

  updateChatMessageFeedback(id, feedback) {
    return this.app2.firestore.collection("MONARetrieval01").doc(id).update({
      feedback: feedback
    })
  }

  // addMONAChatMessage(message, monaTool) {
  //   console.log("message", message);
  //   return this.app2.firestore.collection("generate").add({
  //     prompt: message.content || "",
  //     sender: message.sender,
  //     tool: monaTool
  //   })
  // }

  // addFormattingAIBefore(message) {
  //   return this.app2.firestore.collection("generate").add({
  //     prompt: message.content || "",
  //     sender: message.sender,
  //     tool: ""
  //   })
  // }

  // deleteMONAChatMessage(messageid) {
  //   return this.app2.firestore.collection("generate").delete(messageid)
  // }
  // 

  AddJobs(profile) {
    return this.app2.firestore.collection("applicantsToCall").add({
      profile: profile,
      jobId: profile.jobId,
      phoneNumber: profile.mobiltelefon || profile.telefon || "",
      email: profile.email,
      telefonnummer: profile.telefon,
      mobilnummer: profile.mobiltelefon,
      jobOfferWorkflow: profile.jobOfferWorkflow,
      firstName: profile.vorname,
      lastName: profile.nachname,
      vorname: profile.vorname,
      nachname: profile.nachname,
      jobTitle: profile.jobTitle,
      quelle: "intern",
      status: "new",
      created: new Date(),
      createdGerman: (new Date()).toLocaleDateString("de-DE"),
      createdISO: (new Date()).toISOString(),
      createdISODate: (new Date()).toISOString().split("T")[0],
      timestamp: new Date().getTime()
    }).then(() => {

    }).catch((err) => {
      if (profile.email) {
        this.alertErrorUpload(profile.email)
      } else if (profile.mobiltelefon || profile.telefon) {
        this.alertErrorUpload(profile.mobiltelefon || profile.telefon)
      } else if (profile.vorname || profile.nachname) {
        this.alertErrorUpload(profile.vorname || profile.nachname)
      } else {
        this.alertErrorUpload("unknown")
      }
    })
  }

  createApplicant(form) {
    this.getApplicants().orderBy("id", "desc").get()
      .then((snap) => {
        if (snap.docs) {
          var id = String(Number(snap.docs[0].id) + 1);
          return this.app2.firestore.collection(FirebaseConstants.applicants).doc(id).set({
            form: form,
            email: form.email,
            id: id
          })
        } else {
          var id = "1001"
          return this.app2.firestore.collection(FirebaseConstants.applicants).doc(id).set({
            form: form,
            email: form.email,
            id: id
          })
        }
      }).catch((err) => {
        var id = "no id";
        return id
      })
  }

  getApplicantEditingData(id) {
    return this.app2.firestore.collection(FirebaseConstants.applicants).doc(id).get()
  }

  async updateApplicantForm(id, form) {
    console.log(form);
    return this.app2.firestore.collection(FirebaseConstants.applicants).doc(id).update({
      vorname: form.vorname || "",
      nachname: form.nachname || "",
      geburtsname: form.geburtsname || "",
      land: form.land || "",
      geburtsdatum: form.geburtsdatum || [''],
      geschlecht: form.geschlecht || [''],
      sprache: form.sprache || [''],
      languages: form.languages || [''],
      ausbildungCV: form.ausbildungCV || [''],
      abschlussCV: form.abschlussCV || [''],
      berufserfahrungCV: form.berufserfahrungCV || [''],
      academicCV: form.academicCV || [''],
      jobProposalsCV: form.jobProposalsCV || [''],
      strasse: form.strasse || "",
      hausnummer: form.hausnummer || [''],
      zimmer: form.zimmer || [''],
      postleitzahl: form.postleitzahl || "",
      wohnort: form.wohnort || "",
      geburtsland: form.geburtsland || "",
      geburtsort: form.geburtsort || "",
      staatsangehoerigkeit: form.staatsangehoerigkeit || "",
      telefonnummer: form.telefonnummer || "",
      handynummer: form.handynummer || "",
      email: form.email || "",
      verifiedEmail: form.verifiedEmail || false,
      kommunikationeins: form.kommunikationeins || ["Telefon"],
      kommunikationzwei: form.kommunikationzwei || ["Email"],
      staplerfahrer: form.staplerfahrer || false,
      staplerfahrerschein: form.staplerfahrerschein || false,
      staplerfahrerscheindatum: form.staplerfahrerscheindatum || [""],
      staplerfahrerscheinfront: form.staplerfahrerscheinfront || false,
      staplerfahrerscheinseitenhub: form.staplerfahrerscheinseitenhub || false,
      staplerfahrerscheinschubmast: form.staplerfahrerscheinschubmast || false,
      gfuendundzwanzig: form.gfuendundzwanzig || false,
      gfuendundzwanzigdatum: form.gfuendundzwanzigdatum || [''],
      kommissionierung: form.kommissionierung || false,
      handscanner: form.handscanner || false,
      maschinenbedienung: form.maschinenbedienung || false,
      fuehrerschein: form.fuehrerschein || false,
      fuehrerscheinklasse: form.fuehrerscheinklasse || [''],
      fahrzeug: form.fahrzeug || [''],
      pkw: form.pkw || false,
      roller: form.roller || false,
      motorrad: form.motorrad || false,
      arbeitsweg: form.arbeitsweg || [''],
      sls: form.sls || true,
      swl: form.swl || true,
      log: form.log || true,
      unternehmen: form.unternehmen || "",
      hauptschulabschluss: form.hauptschulabschluss || false,
      mittlerereife: form.mittlerereife || false,
      ohneschulabschluss: form.ohneschulabschluss || false,
      abitur: form.abitur || false,
      ohneausbildung: form.ohneausbildung || false,
      berufsausbildung: form.berufsausbildung || false,
      bachelor: form.bachelor || false,
      promotion: form.promotion7 || false,
      diplom: form.diplom || false,
      meister: form.meister || false,
      berufsausbildungals: form.berufsausbildungals || [""],
      berufsausbildungmitabschluss: form.berufsausbildungmitabschluss || false,
      weiterebeschaeftigung: form.weiterebeschaeftigung || false,
      weiterebeschaeftigungart: form.weiterebeschaeftigungart || [''],
      geringfuegig: form.geringfuegig || false,
      sozialversicherungspflichtig: form.sozialversicherungspflichtig || false,
      anderezeitarbeitsfirma: form.anderezeitarbeitsfirma || false,
      anderezeitarbeitsfirmawer: form.anderezeitarbeitsfirmawer || [""],
      anderezeitarbeitsfirmakunde: form.anderezeitarbeitsfirmakunde || [""],
      bisherigeberufe: form.bisherigeberufe || [''],
      gesundheitlicheingeschraenkt: form.gesundheitlicheingeschraenkt || false,
      schonmalbeschaeftigt: form.schonmalbeschaeftigt || false,
      sentSMS: form.sentSMS || false,
      sentPhonecall: form.sentPhonecall || false,
      sentEmail: form.sentEmail || false,
      von: form.von || [""],
      bis: form.bis || [""],
      gesundheitlicheingeschraenkttext: form.gesundheitlicheingeschraenkttext || [''],
      nachrichtdisponent: form.nachrichtdisponent || [''],
      verfuegbar: form.verfuegbar || [''],
      schichtbereitschaft: form.schichtbereitschaft || true,
      vollzeit: form.vollzeit || false,
      teilzeit: form.teilzeit || false,
      zweitage: form.zweitage || false,
      dreitage: form.dreitage || false,
      viertage: form.viertage || false,
      minijob: form.minijob || false,
      kurzfristig: form.kurzfristig || false,
      zweischicht: form.zweischicht || false,
      dreischicht: form.dreischicht || false,
      vierschicht: form.vierschicht || false,
      tag: form.tag || false,
      spaet: form.spaet || false,
      samstag: form.samstag || false,
      frueh: form.frueh || false,
      nacht: form.nacht || false,
      sonntag: form.sonntag || false,
      imageUrl: form.imageUrl || [''],
      verifCode: form.verifCode || [""],
      experience: form.experience || "",
      bewerberDate: form.bewerberDate || new Date(),
      identifikationsnummer: form.identifikationsnummer || "",
      angeworben: form.angeworben || "",
      steuerklasse: form.steuerklasse || "",
      krankenkasse: form.krankenkasse || "",
      versicherungsart: form.versicherungsart || "",
      rentenversicherungsnummer: form.rentenversicherungsnummer || "",
      familienstand: form.familienstand || "",
      timestamp: form.timestamp || new Date(),
      notes: form.notes || [''],
      qualifications: form.qualifications || [''],
      customFields: form.customFields || [''],
      mobilnummer: form.mobilnummer || "",
      nationalitaet: form.staatsangehoerigkeit || "",
      form: form || [""],
    });
  }

  updateApplicantMONAFeedback(id, feedback) {
    console.log(feedback);
    return this.app2.firestore.collection(FirebaseConstants.applicants).doc(id).update({
      MONAFeedback: feedback
    });
  }

  updateApplicantMatches(id, feedback) {
    console.log(feedback);
    return this.app2.firestore.collection(FirebaseConstants.applicants).doc(id).update({
      AIMatches: feedback
    });
  }

  updateApplicantResumeFeedback(id, feedback) {
    console.log(feedback);
    return this.app2.firestore.collection(FirebaseConstants.applicants).doc(id).update({
      ResumeFeedback: feedback
    });
  }

  updateApplicantInformation(id, pn, arbeit, form) {
    console.log(form);
    return this.app2.firestore.collection(FirebaseConstants.applicants).doc(id).update({
      status: "mitarbeiter",
      personalnummer: pn,
      Personalnummer: pn,
      Arbeit: arbeit,
      arbeit: arbeit,
      form: form
    });
  }

  getFirestoreDate(date) {
    // Check if 'date' is an instance of Date and is valid
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      console.error('Invalid date object:', date);
      // Handle the error appropriately
      return; // or throw an error
    }

    // If valid, convert to Firestore Timestamp
    const firestoreDate = firebase.firestore.Timestamp.fromDate(date);
    return firestoreDate;
  }

  updateApplicantStatus(id, status) {
    console.log("----------- status changed to: " + status + " ----------- ");
    return this.app2.firestore.collection(FirebaseConstants.applicants).doc(id).update({
      status: status
    });
  }

  updateApplicantWithArray(id, data) {
    console.log(data);
    return this.app2.firestore.collection(FirebaseConstants.applicants).doc(id).update({
      workflowArray: data
    });
  }

  updateApplicant(id: string, data: Partial<any>): Promise<void> {
    return this.app2.firestore.collection("applicants").doc(id).update(data)
      .then(() => {
        console.log(`Applicant ${id} successfully updated.`);
      })
      .catch((error) => {
        console.error(`Error updating applicant ${id}:`, error);
        throw error; // Re-throw the error after logging
      });
  }

  getApplicants(): firebase.firestore.CollectionReference {
    return this.app2.firestore.collection(FirebaseConstants.applicants);
  }

  deleteApplicantField(id: string, field: string): Promise<void> {
    console.log(`----------- Deleting field '${field}' for applicant ID: ${id} ----------- `);
    const updateData: any = {};
    updateData[field] = firebase.firestore.FieldValue.delete();
    return this.updateApplicant(id, updateData);
  }

  updateCalendlyLink(id: string, calendlyLink: string): Promise<void> {
    console.log(`----------- Updating calendly link for applicant ID: ${id} -----------`);
    return this.app2.firestore.collection(FirebaseConstants.applicants).doc(id).update({
      calendlyLink: calendlyLink
    });
  }

  updateJobOfferWorkflowInApplicants(id: string, jobOfferWorkflow: string): Promise<void> {
    console.log(`----------- Updating job offer workflow for applicant ID: ${id} ----------- `);
    return this.app2.firestore.collection(FirebaseConstants.applicants).doc(id).update({
      jobOfferWorkflow: jobOfferWorkflow
    });
  }

  async deleteLastLog(id: string, currentLogs: any[]): Promise<void> {
    if (Array.isArray(currentLogs) && currentLogs.length > 0) {
      const updatedLogs = currentLogs.slice(0, -1);
      console.log(`----------- Deleting last log for applicant ID: ${id} ----------- `);
      await this.updateApplicant(id, { logs: updatedLogs });
    } else {
      console.log(`----------- No logs to delete for applicant ID: ${id} ----------- `);
    }
  }

  addLogToApplicant(id: string, log: any): Promise<void> {
    console.log(`----------- Adding log to applicant ID: ${id} ----------- `);
    return this.app2.firestore.collection(FirebaseConstants.applicants).doc(id).update({
      logs: firebase.firestore.FieldValue.arrayUnion(log)
    });
  }

  async getApplicantsWithCreated() {
    const snapshot = await this.createdQuery.get();
    return snapshot;
  }

  // async getApplicantsWithCreated(): Promise<Observable<QuerySnapshot<firebase.firestore.DocumentData>>> {
  //   let promise: any;
  //   promise = new Promise<Observable<QuerySnapshot<firebase.firestore.DocumentData>>>((resolve, reject) => {
  //     const unsubscribe = this.createdQuery.onSnapshot(
  //       (snapshot: QuerySnapshot<firebase.firestore.DocumentData>) => {
  //         console.log('snapshot received in customerdatabase.service.ts, now resolving Promise');
  //         resolve(of(snapshot));        // Resolve the Promise with the snapshot Observable
  //         unsubscribe();
  //       },
  //       (error: any) => {
  //         reject(error);              // Reject the Promise in case of error occurs
  //         unsubscribe();
  //       }
  //     );
  //   });
  //   return promise;
  // }
  /**
   * Function to get all applicants (old (>30days) and new (<30days)) from the applicants collection in firestore database 
   * @returns applicants: Array of all applicants
   */
  getAllNewAndOldApplicants() {
    let applicants = [];
    return this.app2.firestore.collection(FirebaseConstants.applicants).get().then((snap) => {
      snap.forEach((doc: any) => {
        let applicant = doc.data();
        applicant.id = doc.id;
        applicants.push(applicant);
      });
      return applicants;
    });
  }

  async getContingentApplicants(timestamp) {
    let applicants = [];
    let activeApplicants = await this.fetchContingentApplicants(this.getApplicants(), timestamp);
    let deletedApplicants = await this.fetchContingentApplicants(this.getDeletedApplicants(), timestamp);
    return applicants.concat(activeApplicants, deletedApplicants);
  }

  async fetchContingentApplicants(collection, timestamp) {
    let applicants = [];
    // let date = new Date(timestamp.seconds * 1000)
    // await collection.where('created', '>=', date).get().then((snap) => {
    //   snap.forEach((doc: any) => {
    //     let applicant = doc.data();
    //     applicant.id = doc.id;
    //     applicants.push(applicant);
    //   });
    // });
    return applicants;
  }

  getDeletedApplicants() {
    return this.app2.firestore.collection(FirebaseConstants.deletedApplicants);
  }

  getApplicantProfiles(): firebase.firestore.CollectionReference {
    return this.app2.firestore.collection(FirebaseConstants.applicantProfile);
  }

  getApplicantProfileDetail(id): firebase.firestore.DocumentReference {
    return this.app2.firestore.collection("applicantProfile").doc(id);
  }

  getPicklistApplicantContracts(): firebase.firestore.CollectionReference {
    return this.app2.firestore.collection("kunden");
  }

  createWorkflow(form) {
    return this.app2.firestore.collection(FirebaseConstants.workflows).add({
      name: form.name,
      appliesto: form.appliesto,
      whenthishappens: form.whenthishappens,
      dothis: form.dothis,
      id: form.id,
      receiver: form.receiver,
      calendlyLink: form.calendlyLink || "",
      when: form.when,
      receivers: form.receivers || [],
      created: new Date(),
      createdISO: new Date().toISOString(),
      createdGerman: new Date().toLocaleString('de-DE'),
      // new ones
      workflowSendEmailSubject: form.workflowSendEmailSubject,
      emails: form.emails || [],
      emailText: form.emailText || "",
      smsCountryCode: form.smsCountryCode || "",
      smsPhoneNumbers: form.smsPhoneNumbers || "",
      //added phoneNumber and WhatsappNumber (smsPhoneNumbers when undefined should also be [], maybe change in the future) (31.10.2024)
      callPhoneNumbers: form.callPhoneNumbers || [],
      whatsAppPhoneNumbers: form.whatsAppPhoneNumbers || [],
      smsText: form.smsText || "",
      information: form.information || [],
      dynamicAttachment: form.dynamicAttachment || "",
      workflowSendEmailMessage: form.workflowSendEmailMessage || "",
      customTime: form.customTime || {},
      attachment: form.attachment || "",
      attachmentURL: form.attachmentURL || "",
      document: form.document || "",
      documentURL: form.documentURL || "",
      agent: form.agent || "",
      prompt: form.prompt || "",
      status: "aktiv",
      // superior changes
      lastChosenWorkflow: form.lastChosenWorkflow || "",
      //priority of workflow should be priorityOfWorkflow. If old workflow have some broken things, maybe revert, but think of another way to save this. (31.10.2024)
      // priorityOfWorkflow: form.lastChosenWorkflowDate || "",
      priorityOfWorkflow: form.priorityOfWorkflow || "",
      subWorkflows: form.subWorkflows || [],
      lastChosenWorkflowMatching: form.lastChosenWorkflowMatching || {},
      reminder: form.reminder || {},
    });
  }

  isLeffer(): boolean {
    //CustomerNumber 1011 is Leffer
    //Checking the customerNumber because at the moment just Leffer has RecruitmentTests (11 May 2023)
    if (this.customerNumber === 1011) {
      return true;
    }
    return false;
  }

  createDeletedJobOffer(id, data) {
    return this.app2.firestore.collection(FirebaseConstants.deletedJobOffers).doc(id).set(data);
  }


  createJobOffer(form) {
    var date = this.getActualDate();
    console.log(form);
    return this.app2.firestore.collection(FirebaseConstants.jobOffers).add({
      jobTitle: form.jobTitle || "",
      introduction: form.introduction || "",
      tasks: form.tasks || "",
      qualifications: form.qualifications || "",
      benefits: form.benefits || "",
      closingwords: form.closingwords || "",
      money: form.money || "",
      education: form.education || "",
      experience: form.experience || "",
      employment: form.employment || "",
      careerLevel: form.careerLevel || "",
      jobCategory: form.jobCategory || "",
      applicationForm: form.applicationForm || "",
      employmentType: form.employmentType || "",
      applicationTime: form.applicationTime || "",
      country: form.country || "",
      public: form.public,
      city: form.city || "",
      assessmentTestTitle: form.assessmentTestTitle || "",
      uploadLogo: form.uploadLogo || "",
      selectedFont: form.selectedFont || "Manrope",
      selectedHeaderColor: form.selectedHeaderColor || "",
      selectedBodyColor: form.selectedBodyColor || "",
      selectedCompanyTitle: form.selectedCompanyTitle || "#ffffff",
      creationDate: date || "",
      documents: form.documents || [],
      isFromFirebase: true,
      avatar: form.avatar || {},
      languages: form.languages || [],
      messages: form.messages || [],
      conditionalWorkflow: form.conditionalWorkflow || {},
      flowcharts: form.flowcharts || [],
      isAutomaticApplication: form.isAutomaticApplication || false,
      isAutomaticContract: form.isAutomaticContract || false,
      isAutomaticVoice: form.isAutomaticVoice || false,
      isAutomaticCV: form.isAutomaticCV || false,
      isFeedback: form.isFeedback || true,
      codingChallenge: form.codingChallenge || {},
      status: form.status || "",
      created: new Date(),
      updated: new Date(),
      qnaPairs: form.qnaPairs || [],
      testmode: form.testmode || false,
      dfcxAgent: form.dfcxAgent || "",
      isFastApplication: form.isFastApplication,
      isDetailedApplication: form.isDetailedApplication,
      isAutomaticRecruitmentTest: form.isAutomaticRecruitmentTest || false,
      isQandAAllowed: form.isQandAAllowed || false,
      isForcedLanguage: form.isForcedLanguage || false,
      defaultJobOfferLanguage: form.defaultJobOfferLanguage || ""
    }).then(function (docRef) {
      console.log(docRef.id);
      return docRef.id
    })
  }

  createZvooveJob(job) {
    let domParser = new DOMParser();
    return this.app2.firestore.collection(FirebaseConstants.jobOffers).add({
      applicationForm: "Deutsch",
      applicationTime: "",
      avatar: { avatarId: "anna_costume1_cameraA", avatarName: "MONA", name: "Anna", toggle: true },
      benefits: domParser.parseFromString(job.Perspektiven, 'text/html').body.textContent || "",
      careerLevel: "",
      city: job.EinsatzortOrt || "",
      closingWords: "",
      codingChallenge: [],
      conditionalWorkflow: job.conditionalWorkflow,
      country: "",
      created: new Date(),
      dfcxAgent: "",
      documents: {},
      education: "",
      employment: "",
      employmentType: "",
      experience: "",
      introduction: domParser.parseFromString(job.Arbeitgebervorstellung, 'text/html').body.textContent || "",
      isAutomaticApplication: true,
      isAutonaticCV: false,
      isAutomaticContract: false,
      isAutomaticRecruitmentTest: false,
      isAutomaticVoice: false,
      isDetailedApplication: false,
      isFastApplication: false,
      isQandAAllowed: false,
      isForcedLanguage: false,
      jobCategory: "",
      jobTitle: domParser.parseFromString(job.BezeichnungAusschreibung, 'text/html').body.textContent || "",
      languages: [{ language: "deutsch", toggle: true }],
      messages: job.messages,
      money: "",
      public: true,
      qnaPairs: [],
      qualifications: domParser.parseFromString(job.FachlicheAnforderungen, 'text/html').body.textContent || "",
      status: "aktiv",
      tasks: domParser.parseFromString(job.Aufgaben, 'text/html').body.textContent || "",
      updated: new Date(),
    })
  }

  archiveJobOffer(id) {
    return this.app2.firestore.collection(FirebaseConstants.jobOffers).doc(id).update({
      status: "archiviert"
    });
  }
  templateJobOffer(id) {
    return this.app2.firestore.collection(FirebaseConstants.jobOffers).doc(id).update({
      status: "template"
    });
  }

  updateExternalIdForJobOffer(externalId, id) {
    return this.app2.firestore.collection(FirebaseConstants.jobOffers).doc(id).update({
      externalId: externalId
    });
  }

  updatejobOfferInformationAI(id, form) {
    return this.app2.firestore.collection("indexstellenanzeigen").doc(id).update({
      AIFeedback: form,
      status: "AI"
    });
  }

  updateJobOfferApplicantsToZero(id: string) {
    this.app2.firestore.collection(FirebaseConstants.jobOffers).doc(id).update({
      applicants: []
    })
  }

  async ReactivateJobOffer(id, form) {
    const res = (await this.getDeletedJobOffersDetail(id).get()).data();
    console.log(res);
    await this.app2.firestore.collection(FirebaseConstants.jobOffers).doc(id).set(res);
    this.updateJobOffer(id, form);
    await this.app2.firestore.collection(FirebaseConstants.deletedJobOffers).doc(id).delete();
  }
  async updateJobOffer(id, form) {
    var date = this.getActualDate();
    await this.app2.firestore.collection(FirebaseConstants.jobOffers).doc(id).update({
      jobTitle: form.jobTitle || "",
      introduction: form.introduction || "",
      tasks: form.tasks || "",
      qualifications: form.qualifications || "",
      benefits: form.benefits || "",
      closingwords: form.closingwords || "",
      money: form.money || "",
      education: form.education || "",
      experience: form.experience || "",
      employment: form.employment || "",
      careerLevel: form.careerLevel || "",
      jobCategory: form.jobCategory || "",
      applicationForm: form.applicationForm || "",
      employmentType: form.employmentType || "",
      applicationTime: form.applicationTime || "",
      country: form.country || "",
      public: form.public || "",
      testmode: form.testmode || false,
      city: form.city || "",
      assessmentTestTitle: form.assessmentTestTitle || "",
      uploadLogo: form.uploadLogo || "",
      selectedFont: form.selectedFont || "Manrope",
      selectedHeaderColor: form.selectedHeaderColor || "",
      selectedBodyColor: form.selectedBodyColor || "",
      selectedCompanyTitle: form.selectedCompanyTitle || "#ffffff",
      creationDate: date || "",
      documents: form.documents || [],
      languages: form.languages || [],
      avatar: form.avatar || {},
      messages: form.messages || [],
      conditionalWorkflow: form.conditionalWorkflow || {},
      flowcharts: form.flowcharts || [],
      status: form.status,
      qnaPairs: form.qnaPairs || [],
      dfcxAgent: form.dfcxAgent || "",
      isAutomaticApplication: form.isAutomaticApplication || false,
      isAutomaticContract: form.isAutomaticContract || false,
      isAutomaticVoice: form.isAutomaticVoice || false,
      isAutomaticCV: form.isAutomaticCV || false,
      isAutomaticRecruitmentTest: form.isAutomaticRecruitmentTest || false,
      isQandAAllowed: form.isQandAAllowed || false,
      isForcedLanguage: form.isForcedLanguage || false,
      isFeedback: form.isFeedback || false,
      codingChallenge: form.codingChallenge || {},
      updated: new Date(),
      defaultJobOfferLanguage: form.defaultJobOfferLanguage || ""
    })
  }

  private getActualDate() {
    var date = new Date();
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0');
    var yyyy = date.getFullYear();
    var today = dd + '.' + mm + '.' + yyyy;
    return today;
  }

  getContracts(): firebase.firestore.CollectionReference {
    return this.app2.firestore.collection(FirebaseConstants.contracts);
  }

  getContract(id): firebase.firestore.DocumentReference {
    return this.app2.firestore.collection(FirebaseConstants.contracts).doc(id)
  }

  getContractyName(name) {
    return this.app2.firestore.collection(FirebaseConstants.contracts).where("name", "==", name);
  }

  getContractSrc(name) {
    var storageRef = this.storage.ref(name);
    return storageRef.getDownloadURL().toPromise();
  }

  getNewContractId() {
    return this.newContractId;
  }

  async deleteContract(id) {
    this.getContract(id).get().then((snap) => {
      var filepath = snap.data().downloadURL;
      this.storage.storage.refFromURL(filepath).delete();
    }).then(() => {
      this.getContract(id).delete().then(() => {
        console.log("deleted");
      });
    })
  }

  async downloadContract(filename, id) {
    await this.getContract(id).get().then((snap) => {
      var filepath = snap.data().downloadURL;
      this.http.get(filepath, { responseType: 'blob' }).subscribe((res) => {
        var a = document.createElement('a');
        var url = window.URL.createObjectURL(res);
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      })
    })
  }

  deleteApplicantFile(downloadURL, applicantId) {
    this.app2.firestore.collection(FirebaseConstants.applicants).doc(applicantId).get().then((snap) => {
      var data = snap.data();
      var files = data.files
      var newFiles = []
      files.forEach((file) => {
        if (file.downloadURL != downloadURL) {
          newFiles.push(file);
        }
      })
      this.storage.storage.refFromURL(downloadURL).delete();
      this.app2.firestore.collection(FirebaseConstants.applicants).doc(applicantId).update({ files: newFiles });
    })
  }

  storeFilesFirebase(image, collection: string, documentId?: string, type?: string) {
    if (documentId) {
      var files = this.getApplicantFiles(documentId);
      files.then((snap) => {
        var f = [];
        if (snap) {
          if (type) {
            snap.push({
              createdISO: image.createdISO,
              name: image.name,
              downloadURL: image.downloadURL,
              type: type
            })
          } else {
            snap.push({
              createdISO: image.createdISO,
              name: image.name,
              downloadURL: image.downloadURL
            })
          }
          // f.push({
          //   createdISO: image.createdISO,
          //   name: image.name,
          //   downloadURL: image.downloadURL
          // })
          this.app2.firestore.collection(collection).doc(documentId).update({
            files: snap
          })
        } else {
          if (type) {
            f.push({
              createdISO: image.createdISO,
              name: image.name,
              downloadURL: image.downloadURL,
              type: type
            })
          } else {
            f.push({
              createdISO: image.createdISO,
              name: image.name,
              downloadURL: image.downloadURL
            })
          }
          this.app2.collection(collection).doc(documentId).update({
            files: f
          })
        }
      })
    } else {
      this.app2.firestore.collection(collection)
        .add(image)
        .then((res) => {
          this.newContractId = res.id;
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  }

  storeAICVFeedback(feedback) {
    this.app2.collection(FirebaseConstants.aiCVFeedback).add({
      feedback: feedback,
      created: new Date()
    })
  }

  storeAICVFeedbackForMatching(id, feedback) {
    this.app2.collection(FirebaseConstants.applicants).doc(id).update({
      feedbackForMatching: feedback,
      created: new Date(),
      isFeedbackForMatching: true,
      createdISODate: new Date().toISOString(),
      createdISO: new Date().toISOString().slice(0, 10)
    })
  }

  getImageRef(fileStoragePath) {
    return this.storage.ref(fileStoragePath);
  }

  getCustomerImageRef(fileStoragePath) {
    return this.customerStorage.ref(fileStoragePath);
  }

  getCustomerDBImageRef(fileStoragePath) {
    console.log("this.storage", this.storage);
    return this.customerStorage.ref(fileStoragePath);
  }

  getCustomerUploadTask(fileStoragePath, file) {
    return this.customerStorage.upload(fileStoragePath, file);
  }

  getUploadTask(fileStoragePath, file) {
    return this.storage.upload(fileStoragePath, file);
  }

  saveAIDisclaimer(userId, disclaimer) {
    const disclaimerString = JSON.stringify(disclaimer);
    this.coolStore.firestore.collection("customers").doc(userId).collection("disclaimers").add({
      created: new Date(),
      createdISO: (new Date()).toISOString(),
      createdGerman: (new Date()).toLocaleDateString("de-DE"),
      disclaimerSeen: true,
      disclaimerString: disclaimerString
    })
  }

  confirmedAIDisclaimer(userId) {
    this.coolStore.firestore.collection("customers").doc(userId).update({
      disclaimerConfirmed: true,
      disclaimerConfirmedDate: new Date()
    })
  }

  returnSeenDisclaimerStatus() {
    return this.seenDisclaimer;
  }

  returnConfirmedDisclaimerStatus() {
    return this.confirmedDisclaimer;
  }

  returnUserId() {
    return this.userId;
  }

  setUserStorage(uid) {
    this.coolStore.firestore.collection("customers").doc(uid).get()
      .then((snap) => {
        var customerData = snap.data();
        this.seenDisclaimer = customerData.disclaimerSeen || false;
        this.confirmedDisclaimer = customerData.disclaimerConfirmed || false;
        this.userId = uid;
        var user = customerData.kundennummer;
        this.coolStore.firestore.collection(FirebaseConstants.companies).where(FirebaseConstants.kundennummer, "==", user).get().then((snap) => {
          if (snap.docs.length > 0) {
            var data = snap.docs[0].data()
            var platformId = PLATFORM_ID;
            this.customerStorage = new AngularFireStorage(data.database, "customerStorage", data.database.storageBucket, platformId, this.zone);
          }
        })
      })
  }

  getAllVideos() {
    // part for new video generation
    // return this.videoStore.firestore.collection(FirebaseConstants.videos);
    return this.app2.firestore.collection(FirebaseConstants.videos);
  }

  getTeammembers(): firebase.firestore.CollectionReference {
    return this.app2.firestore.collection(FirebaseConstants.teammembers);
  }

  AddBlacklistCustomer(item) {
    this.app2.firestore.collection(FirebaseConstants.blacklist).add({
      email: item
    });
  }

  RemoveBlacklistCustomer(item) {
    this.app2.firestore.collection(FirebaseConstants.blacklist).where("email", "==", item).get().then((snap) => {
      snap.forEach((doc) => {
        this.app2.collection(FirebaseConstants.blacklist).doc(doc.id).delete();
      });
    });
  }

  GetBlacklist(): firebase.firestore.CollectionReference {
    return this.app2.firestore.collection(FirebaseConstants.blacklist);
  }

  async updateTeammemberLocation(email, location) {
    return this.app2.firestore.collection(FirebaseConstants.teammembers).where("email", "==", email).get().then((snap) => {
      snap.forEach((doc) => {
        this.app2.firestore.collection(FirebaseConstants.teammembers).doc(doc.id).update({
          locationName: location
        });
      });
    });
  }

  retreiveTeammemberStatusByEmail(): firebase.firestore.CollectionReference {
    return this.app2.firestore.collection(FirebaseConstants.teammembers);
  }

  async updateTeammemberStatus(email, value) {
    return this.app2.firestore.collection(FirebaseConstants.teammembers).where("email", "==", email).get().then((snap) => {
      console.log("snap", snap);
      snap.forEach((doc) => {
        this.app2.firestore.collection(FirebaseConstants.teammembers).doc(doc.id).update({
          status: value
        }).then((snap) => {
          console.log(snap);
        }).catch((err) => {
          console.log(err);
        })
      });
    });
  }

  inviteTeammemberIntoSubCollection(email, accountId) {
    this.app2.collection(FirebaseConstants.teammembers).add(
      {
        email: email,
        status: "Mitarbeiter*in",
        accountId: accountId,
        created: new Date(),
        createdIso: new Date().toISOString().split("T")[0],
        createdIsoTime: new Date().toISOString()
      }
    );
    this.coolStore.firestore.collection(FirebaseConstants.customers).add(
      {
        email: email,
        kundennummer: this.customerNumber,
        accountId: accountId,
        created: new Date(),
        createdIso: new Date().toISOString().split("T")[0],
        status: "teammember",
        createdIsoTime: new Date().toISOString()
      })
  }

  async inviteTeammember(email) {
    this.app2.collection(FirebaseConstants.teammembers).add(
      {
        email: email,
        status: "Mitarbeiter*in",
        created: new Date(),
        createdIso: new Date().toISOString().split("T")[0],
        createdIsoTime: new Date().toISOString()
      }
    );
    this.coolStore.firestore.collection(FirebaseConstants.customers).add(
      {
        email: email,
        kundennummer: this.customerNumber,
        created: new Date(),
        createdIso: new Date().toISOString().split("T")[0],
        status: "Mitarbeiter*in",
        createdIsoTime: new Date().toISOString()
      })
  }

  async getTeammember(email) {
    return this.app2.firestore.collection(FirebaseConstants.teammembers).where("email", "==", email).get();
  }

  async getTeammemberCoolstore(id) {
    return this.coolStore.firestore.collection(FirebaseConstants.customers).doc(id).get();
  }

  updateTeammember(form) {
    this.app2.firestore.collection(FirebaseConstants.teammembers).doc(form.value.teammemberId).update({
      email: form.value.email,
      name: form.value.firstname + " " + form.value.lastname,
      status: "Mitarbeiter*in"
    })
  }

  async updateCustomerTeamMember(kundennummer, uid, customerID) {
    let docRef = await this.coolStore.firestore.collection(FirebaseConstants.customers).doc(uid);
    let doc = await docRef.get();
    if (doc.exists) {
      docRef.update({
        kundennummer: kundennummer
      });
      this.coolStore.firestore.collection(FirebaseConstants.customers).doc(customerID).delete();
    } else {
      this.updateCustomerTeamMember(kundennummer, uid, customerID);
    }
  }

  getCompanyId() {
    var id = ""
    this.coolStore.firestore.collection('companies')
      .where(FirebaseConstants.kundennummer, "==", this.customerNumber).get().then((snap) => {
        snap.forEach((doc) => {
          return doc.id;
        })
      });
  }

  getCompany() {
    return this.coolStore.firestore.collection('companies').where(FirebaseConstants.kundennummer, "==", this.customerNumber).get();
  }

  getCompanyName(): Promise<string> {
    return this.getCompany().then((snap) => {
      const docs = snap.docs
      if (docs.length > 0) {
        var doc = docs[0];
        var companyName = doc.data().name || doc.data().email;
        return Promise.resolve(companyName);
      }
    })

  }

  getDashboardSettings() {
    return this.app2.firestore.collection(FirebaseConstants.dashboardSettings).get().then((snap) => {
      if (snap == undefined) {
        this.app2.firestore.collection(FirebaseConstants.dashboardSettings).add({});
      } else {
        let docs = snap.docs;
        if (docs != undefined && docs.length > 0) {
          return docs[0].data();
        }
      }
    });
  }

  updateDashboardSettings(timeDisponentSetting, moneyDisponentSetting) {
    this.app2.firestore.collection(FirebaseConstants.dashboardSettings).get().then((snap) => {
      if (snap.docs.length > 0) {
        snap.docs[0].ref.update({
          timeDisponentSetting: timeDisponentSetting,
          moneyDisponentSetting: moneyDisponentSetting
        })
      } else {
        this.app2.firestore.collection(FirebaseConstants.dashboardSettings).add({
          timeDisponentSetting: timeDisponentSetting,
          moneyDisponentSetting: moneyDisponentSetting
        })
      }
    });
  }

  getCustomerPrice() {
    return this.coolStore.firestore.collection(FirebaseConstants.companies).where(FirebaseConstants.kundennummer, "==", this.customerNumber).get();
  }

  async getCustomer(email) {
    return this.coolStore.firestore.collection(FirebaseConstants.customers).where("email", "==", email).get();
  }

  signupTeammember(email, password) {
    this.auth = new AngularFireAuth(this.db, 'CustomerDatabase', PLATFORM_ID, this.zone);

    return this.auth.createUserWithEmailAndPassword(email, password).then((newUserCredential: firebase.auth.UserCredential) => {
      firebase.firestore().doc(`/customers/${newUserCredential.user.uid}`).set({ email });
    }).catch(error => {
      console.error(error);
      throw new Error(error);
    });
  }

  deleteTeammember(email) {
    this.coolStore.firestore.collection(FirebaseConstants.customers).where("email", "==", email).get().then((snap) => {
      snap.forEach((doc) => {
        this.coolStore.firestore.collection(FirebaseConstants.customers).doc(doc.id).delete();
      })
    })
    this.app2.firestore.collection(FirebaseConstants.teammembers).where("email", "==", email).get().then((snap) => {
      snap.forEach((doc) => {
        this.app2.firestore.collection(FirebaseConstants.teammembers).doc(doc.id).delete();
      })
    })

    var body = {
      email: email,
      customerNumber: this.customerNumber
    };
    return this.http.post('https://europe-west3-mona-ai-pwa-firebase.cloudfunctions.net/delete-user-mona-plattform', body, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  deactivateAccount(email) {
    var body = {
      email: email,
      customerNumber: this.customerNumber
    };
    return this.http.post('https://europe-west3-mona-ai-pwa-firebase.cloudfunctions.net/deactivate-user-mona-plattform', body, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  addmatchingSettings(settings) {
    this.app2.firestore.collection(FirebaseConstants.matchingSettings).add({
      settings: settings,
      created: new Date(),
      createdIso: new Date().toISOString().split("T")[0],
      createdIsoTime: new Date().toISOString()
    })
  }

  getMatchingSettings() {
    return this.app2.firestore.collection(FirebaseConstants.matchingSettings)
  }

  deleteWorkflow(id) {
    return this.app2.firestore.collection("workflows").doc(id).delete();
  }

  async getParticularWorkflow(id) {
    const docRef = this.app2.firestore.collection("workflows").doc(id);
    const docSnapshot = await docRef.get();
    if (docSnapshot.exists) {
      return docSnapshot.data();
    } else {
      return undefined;
    }
  }

  async ImportProfilAnlegen(profil) {
    return await this.app2.firestore.collection("applicants").add({
      anrede: profil.anrede || "",
      anschrift: profil.anschrift || "",
      berufswunsch: profil.berufswunsch || "",
      eingangsdatum: profil.eingangsdatum || "",
      email: profil.email || "",
      externeId: profil.externeId || "",
      facebook: profil.facebook || "",
      fuehrerschein: profil.fuehrerschein || "",
      fachkenntnisse: profil.fachkenntnisse || "",
      familienstand: profil.familienstand || "",
      feld1: profil.feld1 || "",
      feld2: profil.feld2 || "",
      feld3: profil.feld3 || "",
      feld4: profil.feld4 || "",
      feld5: profil.feld5 || "",
      geburtsdatum: profil.geburtsdatum || "",
      gehaltswunsch: profil.gehaltswunsch || "",
      hausnummer: profil.hausnummer || "",
      instagram: profil.instagram || "",
      interneId: profil.interneId || "",
      linkedin: profil.linkedin || "",
      mindestgehalt: profil.mindestgehalt || "",
      mobiltelefon: profil.mobiltelefon || "",
      nachname: profil.nachname || "",
      nationalitaet: profil.nationalitaet || "",
      notizen: profil.notizen || "",
      ort: profil.ort || "",
      pkw: profil.pkw || "",
      plz: profil.plz || "",
      quelle: profil.quelle || "",
      reisewunsch: profil.reisewunsch || "",
      socialmedia: profil.socialmedia || "",
      sprachen: profil.sprachen || "",
      status: profil.status || "",
      strasse: profil.strasse || "",
      telefon: profil.telefon || "",
      titel: profil.titel || "",
      umzugwunsch: profil.umzugwunsch || "",
      vorname: profil.vorname || "",
      xing: profil.xing || "",
      clicks: 0,
      seen: 0,
      profilStatus: "aktiv",
      created: new Date(),
      IsoDate: new Date().toISOString().split("T")[0],
      lastChanged: new Date(),
      lastChangedIsoDate: new Date().toISOString().split("T")[0]
    });
  }

  profilAnlegen(profil) {
    return this.applicantProfile.add({
      anrede: profil.anrede,
      anschrift: profil.anschrift,
      applicationid: profil.applicationid,
      bereich: profil.bereich,
      beschreibung: profil.beschreibung,
      bewerberEinwilligung: profil.bewerberEinwilligung,
      bewertung: profil.bewertung,
      eingangsdatum: profil.eingangsdatum,
      einrichtung: profil.einrichtung,
      email: profil.email,
      endDatum: profil.endDatum,
      fuehrerschein: profil.fuehrerschein,
      geaendertdatum: profil.geaendertdatum,
      jobpost: profil.jobpost,
      kategorie: profil.kategorie,
      mobiltelefon: profil.mobiltelefon,
      nachname: profil.nachname,
      newsletter: profil.newsletter,
      notizen: profil.notizen,
      ort: profil.ort,
      pkw: profil.pkw,
      plz: profil.plz,
      positionen: profil.positionen,
      recruitmentStartdatum: profil.recruitmentStartdatum,
      schlagworte: profil.schlagworte,
      startDatum: profil.startDatum,
      status: profil.status,
      strasse: profil.strasse,
      talentpoolEinwilligung: profil.talentpoolEinwilligung,
      talentpoolEinwilligungDouble: profil.talentpoolEinwilligungDouble,
      telefon: profil.telefon,
      titel: profil.titel,
      unternehmen: profil.unternehmen,
      vorname: profil.vorname,
      clicks: 0,
      seen: 0,
      profilStatus: "aktiv",
      created: new Date(),
      createdFirestore: firebase.firestore.FieldValue.serverTimestamp(),
      IsoDate: new Date().toISOString().split("T")[0],
      lastChanged: new Date(),
      lastChangedIsoDate: new Date().toISOString().split("T")[0]
    });
  }

  profilUpdate(profil) {
    return this.applicantProfile.doc(profil.id).update({
      anrede: profil.anrede,
      anschrift: profil.anschrift,
      applicationId: profil.applicationId,
      bereich: profil.bereich,
      beschreibung: profil.beschreibung,
      bewerberEinwilligung: profil.bewerberEinwilligung,
      bewertung: profil.bewertung,
      eingangsdatum: profil.eingangsdatum,
      einrichtung: profil.einrichtung,
      email: profil.email,
      endDatum: profil.endDatum,
      fuehrerschein: profil.fuehrerschein,
      geandertdatum: profil.geandertdatum,
      jobpost: profil.jobpost,
      kategorie: profil.kategorie,
      clicks: profil.clicks,
      seen: profil.seen,
      mobiltelefon: profil.mobiltelefon,
      nachname: profil.nachname,
      newsletter: profil.newsletter,
      notizen: profil.notizen,
      ort: profil.ort,
      pkw: profil.pkw,
      plz: profil.plz,
      positionen: profil.positionen,
      recruitmentStartdatum: profil.recruitmentStartdatum,
      schlagworte: profil.schlagworte,
      startDatum: profil.startDatum,
      status: profil.status,
      strasse: profil.strasse,
      talentpoolEinwilligung: profil.talentpoolEinwilligung,
      talentpoolEinwilligungDouble: profil.talentpoolEinwilligungDouble,
      telefon: profil.telefon,
      titel: profil.titel,
      profilStatus: "aktiv",
      unternehmen: profil.unternehmen,
      vorname: profil.vorname,
      created: profil.created,
      createdFirestore: profil.createdFirestore,
      IsoDate: profil.IsoDate,
      lastChanged: new Date(),
      lastChangedIsoDate: new Date().toISOString().split("T")[0]
    });
  }

  profilDelete(id) {
    return this.applicantProfile.doc(id).delete();
  }

  profilArchive(id) {
    return this.applicantProfile.doc(id).update({
      profilStatus: "archiviert",
      lastChanged: new Date(),
      lastChangedIsoDate: new Date().toISOString().split("T")[0]
    })
  }

  updateContractMessages(messages, docId) {
    const messagesJSON = JSON.stringify(Array.from(messages.entries()));
    this.getContract(docId).update(FirebaseConstants.messages, messagesJSON)
  }

  alertErrorUpload(kennziffer) {
    this.alertCtrl.create({
      header: 'Fehler beim Upload von ' + kennziffer,
      message: 'Bitte versuchen Sie es für die Person erneut.',
      buttons: ['OK']
    }).then(alert => alert.present());
  }

  createCompleetApplicant(obj) {
    this.app2.firestore.collection("compleetApplicants").add(obj);
  }

  getApplicantsAfterDate(date) {
    return this.app2.firestore.collection(FirebaseConstants.applicants).where("created", ">=", date);
  }

  getCompleetApplicant() {
    let startDate = new Date(2025, 0, 1);
    let endDate = new Date(2025, 0, 31);

    let applicants = [];
    this.app2.firestore.collection("applicants").where("quelle", "==", "compleet").get().then(async (snap) => {
      await snap.docs.forEach((doc) => {
        let data = doc.data();
        let created = data.created.toDate();
        if (created >= startDate && created <= endDate) {
          applicants.push(data);
        }
      })
    }).then(() => {
      console.log(applicants.length);
    })
  }

  async triggerProfileCreation(applicantId) {
    let applicant = await this.app2.firestore.collection(FirebaseConstants.applicants).doc(applicantId);
    applicant.update({
      profileCreated: null,
      workflowArray: null
    }).then(async () => {
      let workflows = [];
      await this.app2.firestore.collection(FirebaseConstants.workflows).get().then((snap) => {
        let docs = snap.docs;
        if (docs) {
          for (let doc of docs) {
            let workflow = doc.data();
            let dynamicAttachment = workflow.dynamicAttachment;
            switch (dynamicAttachment) {
              case "applicantProfileForCustomerAndMatching":
                workflows.push(doc.id);
                break;
              case "applicantProfile":
                workflows.push(doc.id);
                break;
              case "applicantProfileForCustomer":
                workflows.push(doc.id);
                break;
              case "applicantProfileForDisponentAndMatching":
                workflows.push(doc.id);
                break;
              case "profilvertriebWithoutCreatingProfile":
                workflows.push(doc.id);
                break;
              default:
                break;
            }
          }
        }
      });
      applicant.get().then((snap) => {
        let data = snap.data();
        let workflowArrayExecuted = data.workflowArrayExecuted;
        let newWorkflowsToExecute = [];
        if (workflowArrayExecuted) {
          for (let workflow of workflows) {
            if (workflowArrayExecuted.includes(workflow)) {
              newWorkflowsToExecute.push(workflow);
              break;
            }
          }
        }
        if (newWorkflowsToExecute.length > 0) {
          applicant.update({
            "workflowArray": newWorkflowsToExecute
          });
        }
      })
    });
  }

  // agents
  getAgents() {
    return this.app2.firestore.collection(FirebaseConstants.agents);
  }

  addAgent(agent) {
    return this.app2.firestore.collection(FirebaseConstants.agents).add(agent);
  }

  updateAgent(agent) {
    return this.app2.firestore.collection(FirebaseConstants.agents).doc(agent.id).update(agent);
  }

  deleteAgent(id) {
    return this.app2.firestore.collection(FirebaseConstants.agents).doc(id).delete();
  }

  retrieveChatHistory() {
    return this.app2.firestore.collection(FirebaseConstants.ChatHistory);
  }

  // agents API
  getProfile2(): any {
    return this.app2.collection(FirebaseConstants.branding);
  }

  saveZvooveAPIKeys2(data) {
    return this.getProfile2().get().then((snap) => {
      let docs = snap.docs;
      let docLength = docs.length;
      if (docLength > 0) {
        var id = docs[0].id;
        return this.app2.collection(FirebaseConstants.branding).doc(id).update({
          zvoove: data.zvoove,
          zvooveBewerberApiKey: data.zvooveBewerberApiKey || "",
          zvooveBewerberApiLink: data.zvooveBewerberApiLink || "",
          zvooveStellenApiKey: data.zvooveStellenApiKey || "",
          zvooveStellenApiLink: data.zvooveStellenApiLink || "",
          zvoooveNextLevelApiKey: data.zvoooveNextLevelApiKey || "",
          zvooveNextLevelApiLink: data.zvooveNextLevelApiLink || "",
          zvooveBewerberApiLinkById: data.zvooveBewerberApiLinkById || "",
          zvooveCreateBewerberApiLink: data.zvooveCreateBewerberApiLink || "",
          username: data.username || "",
          password: data.password || "",
          AIMatchingType: data.AIMatchingType || "",
          updated: new Date(),
          status: "aktiv"
        })
      } else {
        return false;
      }
    })
  }

  saveTalentionAPIKeys2(data) {
    return this.getProfile2().get().then((snap) => {
      let docs = snap.docs;
      let docLength = docs.length;
      if (docLength > 0) {
        var id = docs[0].id;
        return this.app2.collection(FirebaseConstants.branding).doc(id).update({
          talention: data.talention,
          talentionClientId: data.talentionClientId || "",
          talentionClientSecret: data.talentionClientSecret || "",
          talentionAccountId: data.talentionAccountId || "",
          talentionAccountOption: data.talentionAccountOption || "",
          talentionAccountOptionBranches: data.talentionAccountOptionBranches || "",
          talentionMonaStatusEingang: data.talentionMonaStatusEingang || "",
          talentionMonaStatusImInterview: data.talentionMonaStatusImInterview || "",
          talentionMonaStatusInterviewAbgeschlossen: data.talentionMonaStatusInterviewAbgeschlossen || "",
          talentionMonaStatusProfilErstellt: data.talentionMonaStatusProfilErstellt || "",
          talentionMonaStatusProfilVersendet: data.talentionMonaStatusProfilVersendet || "",
          AIMatchingType: data.AIMatchingType || "",
          updated: new Date(),
          status: "aktiv"
        })
      } else {
        return false;
      }
    })
  }

  saveIndexApiKeyCredentials(accessToken, login, password) {
    return this.getProfile().get().then((snap) => {
      let docs = snap.docs;
      let docLength = docs.length;
      if (docLength > 0) {
        var id = docs[0].id;
        return this.app2.collection(FirebaseConstants.branding).doc(id).update({
          indexApiKey: accessToken,
          indexLogin: login,
          indexPassword: password,
          updated: new Date()
        })
      } else {
        return false;
      }
    })
  }

  saveGoogleCredentials(accessToken, refreshToken, googleProfile) {
    return this.getProfile().get().then((snap) => {
      let docs = snap.docs;
      let docLength = docs.length;
      if (docLength > 0) {
        var id = docs[0].id;
        return this.app2.collection(FirebaseConstants.branding).doc(id).update({
          googleAccessToken: accessToken,
          googleRefreshToken: refreshToken,
          googleProfile: googleProfile,
          updated: new Date()
        })
      } else {
        return false;
      }
    })
  }

  saveMicrosoftCredentials(accessToken, refreshToken, microsoftProfile) {
    return this.getProfile().get().then((snap) => {
      let docs = snap.docs;
      let docLength = docs.length;
      if (docLength > 0) {
        var id = docs[0].id;
        const microsoftProfileObj = JSON.parse(microsoftProfile);
        return this.app2.collection(FirebaseConstants.branding).doc(id).update({
          microsoftAccessToken: accessToken,
          microsoftRefreshToken: refreshToken,
          microsoftProfile: microsoftProfileObj,
          updated: new Date()
        })
      } else {
        return false;
      }
    })
  }

  private async updateTeammemberWithMicrosoftCredentials(accessToken, microsoftProfile, refreshToken, snap) {
    snap.docs.forEach((doc) => {
      let id = doc.id;
      let kundenNummer = doc.data().kundennummer;
      if (kundenNummer === this.customerNumber && kundenNummer) {
        this.getTeammembers().doc(id).update({
          microsoftAccessToken: accessToken,
          microsoftRefreshToken: refreshToken,
          microsoftProfile: microsoftProfile
        })
      } else {
        this.addTeammemberWithMicrosoftCredentials(accessToken, microsoftProfile, refreshToken);
      }
    })
  }

  private async addTeammemberWithMicrosoftCredentials(accessToken, microsoftProfile, refreshToken) {
    this.getTeammembers().add({
      email: microsoftProfile.mail,
      kundennummer: this.customerNumber,
      created: new Date(),
      createdIso: new Date().toISOString().split("T")[0],
      status: "Mitarbeiter*in",
      createdIsoTime: new Date().toISOString(),
      microsoftAccessToken: accessToken,
      microsoftRefreshToken: refreshToken,
      microsoftProfile: microsoftProfile
    })
  }

  async saveMicrosoftTeamMemberCredentials(accessToken, refreshToken, microsoftProfile) {
    const microsoftProfileObj = JSON.parse(microsoftProfile);

    this.getTeammembers().where("email", "==", microsoftProfileObj.mail).get()
      .then((snap) => {
        if (snap.docs.length > 0) {
          this.updateTeammemberWithMicrosoftCredentials(accessToken, microsoftProfileObj, refreshToken, snap);
        } else {
          this.addTeammemberWithMicrosoftCredentials(accessToken, microsoftProfileObj, refreshToken);
        }
      })

    this.coolStore.firestore.collection(FirebaseConstants.customers).where("email", "==", microsoftProfileObj.mail).get().then((snap) => {
      if (snap.docs.length > 0) {
        //do nothing as we do not want to update the token here.
      } else {
        //create a new teamMember without token
        this.AddNewCustomerInCoolstore(microsoftProfileObj.mail);
      }
    })
  }

  AddNewCustomerInCoolstore(email) {
    this.coolStore.firestore.collection(FirebaseConstants.customers).add(
      {
        email: email,
        kundennummer: this.customerNumber,
        created: new Date(),
        createdIso: new Date().toISOString().split("T")[0],
        status: "Mitarbeiter*in",
        createdIsoTime: new Date().toISOString()
      })
  }

  saveIMAPCredentials(imapHost, imapPost, imapUsername, imapPassword, imapTLS) {
    return this.getProfile().get().then((snap) => {
      let docs = snap.docs;
      let docLength = docs.length;
      if (docLength > 0) {
        var id = docs[0].id;
        return this.app2.collection(FirebaseConstants.branding).doc(id).update({
          imapHost: imapHost,
          imapPost: imapPost,
          imapUsername: imapUsername,
          imapPassword: imapPassword,
          imapTLS: imapTLS,
          updated: new Date()
        })
      } else {
        return false;
      }
    })
  }

  saveCompleetAPIKeys2(data) {
    return this.getProfile2().get().then((snap) => {
      let docs = snap.docs;
      let docLength = docs.length;
      if (docLength > 0) {
        var id = docs[0].id;
        return this.app2.collection(FirebaseConstants.branding).doc(id).update({
          compleet: data.compleet,
          compleetAPIKeys: data.compleetAPIKeys || "",
          updated: new Date(),
          AIMatchingType: data.AIMatchingType || "",
          status: "aktiv"
        })
      } else {
        return false;
      }
    })
  }

  saveIndexAPIKeys2(data) {
    return this.getProfile2().get().then((snap) => {
      let docs = snap.docs;
      let docLength = docs.length;
      if (docLength > 0) {
        var id = docs[0].id;
        return this.app2.collection(FirebaseConstants.branding).doc(id).update({
          index: data.index,
          indexLogin: data.indexLogin || "",
          indexPassword: data.indexPassword || "",
          updated: new Date(),
        })
      } else {
        return false;
      }
    })
  }

  saveZapierAPIKeys2(data) {
    return this.getProfile2().get().then((snap) => {
      let docs = snap.docs;
      let docLength = docs.length;
      if (docLength > 0) {
        var id = docs[0].id;
        return this.app2.collection(FirebaseConstants.branding).doc(id).update({
          zapier: data.zapier,
          updated: new Date(),
          status: "aktiv"
        })
      } else {
        return false;
      }
    })
  }

  saveCustomIntegrationAPIKeys2(data) {
    return this.getProfile2().get().then((snap) => {
      let docs = snap.docs;
      let docLength = docs.length;
      if (docLength > 0) {
        var id = docs[0].id;
        return this.app2.collection(FirebaseConstants.branding).doc(id).update({
          customIntegration: data.customIntegration,
          updated: new Date(),
          status: "aktiv"
        })
      } else {
        return false;
      }
    })
  }

  saveTalent360APIKeys2(data) {
    return this.getProfile2().get().then((snap) => {
      let docs = snap.docs;
      let docLength = docs.length;
      if (docLength > 0) {
        var id = docs[0].id;
        return this.app2.collection(FirebaseConstants.branding).doc(id).update({
          talent360: data.talent360,
          talentClientId: data.talentClientId || "",
          talentClientSecret: data.talentClientSecret || "",
          talentAPIBaseUrl: data.talentAPIBaseUrl || "",
          talentAccessTokenUrl: data.talentAccessTokenUrl || "",
          talentPublicAudience: data.talentPublicAudience || "",
          updated: new Date(),
          status: "aktiv"
        })
      } else {
        return false;
      }
    })
  }

  sendVideos() {
    // part for new video generation collecting all Videos from customers
    // let i = 1;
    // this.app2.firestore.collection(FirebaseConstants.videos).get().then(async (snap) => {
    //   await snap.forEach((doc) => {
    //     let video = doc.data();
    //     this.videoStore.firestore.collection(FirebaseConstants.videos).add(video).then(() => {
    //       console.log(i++);
    //     })
    //   })
    // })
  }

  async addToVideosToBeGenerated(videosToGenerate: any[]) {
    let collectionRef = await this.app2.firestore.collection(FirebaseConstants.videosInProgress)
    if (collectionRef) {
      videosToGenerate.forEach(async (video) => {
        video.created = new Date();
        await collectionRef.add(video)
      });
    }
  }

  getVideosInProgress() {
    return this.app2.firestore.collection(FirebaseConstants.videosInProgress);
  }

  deleteVideoInProgress(text, language, avatarId) {
    const firestore = this.app2.firestore;
    const collectionRef = firestore.collection(FirebaseConstants.videosInProgress);

    return collectionRef
      .where("text", "==", text)
      .where("langCode", "==", language)
      .where("avatarId", "==", avatarId)
      .get()
      .then((snap) => {
        if (snap.empty) {
          console.log("No matching documents found for deletion.");
          return;
        }

        const batch = firestore.batch();

        snap.forEach((doc) => {
          batch.delete(doc.ref);
        });

        return batch.commit().then(() => {
          console.log("Documents successfully deleted.");
        });
      })
      .catch((error) => {
        console.error("Error deleting documents: ", error);
      });
  }

  getMonaLogs(applicantId) {
    let errors = [];
    return this.app2.firestore.collection(FirebaseConstants.monaLogs).doc(applicantId).collection(FirebaseConstants.errors).get().then((snap) => {
      snap.forEach((doc) => {
        if (doc.exists) {
          let data = doc.data();
          let timestamp = data.created;
          if (timestamp instanceof Date) {
            data.created = timestamp;
          } else {
            data.created = timestamp.toDate();
          }
          errors.push(data);
        }
        // if (snap.exists) {
        //   let data = snap.data();
        //   let errors = data.errorArray;
        //   for (let error of errors) {
        //   }
        //   return errors;
        // };
      });
      return errors;
    });
  }

  // notifications
  public updateLastLoginTime(userId: string): Promise<void> {
    return this.app2.firestore.doc(`users/${userId}`).update({
      lastLoginTime: firebase.firestore.FieldValue.serverTimestamp()
    });
  }

  // Applicants seit letztem Login
  public getApplicantsSince(lastLogin: Date): Observable<any[]> {
    return this.app2.firestore
      .collection('customers/kunde1001/applicants', ref =>
        ref.where('createdAt', '>', lastLogin)
      )
      .valueChanges();
  }

  // Tutorials seit letztem Login
  public getTutorialsSince(lastLogin: Date): Observable<any[]> {
    return this.app2.firestore
      .collection('tutorials', ref => ref.where('creationDate', '>', lastLogin))
      .valueChanges();
  }

  // Integrationen seit letztem Login
  public getIntegrationsSince(lastLogin: Date): Observable<any[]> {
    return this.app2.firestore
      .collection('integrations', ref => ref.where('createdAt', '>', lastLogin))
      .valueChanges();
  }

  public setUserEmail(email: string) {
    this.userEmail = email;
  }

  public getUserEmail(): string {
    return this.userEmail;
  }

  public checkIfUserHasMicrosoftTokens() {
    return new Promise(resolve => {
      this.getTeammembers().where("email", "==", this.userEmail).get().then((snap) => {
        if (snap.docs.length > 0) {
          snap.docs.forEach((doc) => {
            let data = doc.data();
            //should be only 1 doc
            resolve(data.microsoftAccessToken !== undefined && data.microsoftRefreshToken !== undefined);
          })
        }
        resolve(false);
      })
    })
  }
}