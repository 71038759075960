import { Component, PLATFORM_ID, NgZone, ViewChildren, QueryList, ElementRef, ChangeDetectorRef, AfterViewInit, OnInit } from '@angular/core';
import {
  AngularfirestoreCoolStoreService,
  AngularfirestorePerfectStoreService,
} from './services/angularfirestore-extension.service'
import { Observable } from 'rxjs';
import { FirebaseService } from './services/firebase.service';
const customer = "kunde1001";

import { AngularFirestore } from 'angularfire2/firestore';
import { CustomerdatabaseService } from './services/customerdatabase.service';
import { ProfileService } from './services/profile.service';
import { TutorialService } from './services/tutorial.service';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { TranslationObject } from './helper/TranslationObject';
import { MenuService } from './services/menu-service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnInit {
  logo = "../assets/mona_white_logo.png"
  public loginFinished = false;
  ProfileIcon = "";
  coolClctn$: Observable<any>
  perfectClctn$: Observable<any>
  currentUser: any;
  intern = false;
  isAuthenticated: boolean = true;
  admin = "";
  adminBool = false;
  translationObject: any;
  creditsRemaining: number;
  public applicantsRemaining: number;
  enterprise = false;
  currentPlan = "";
  profilePicture = "../assets/icon/megaphone-outline.png";
  public appPages = [];
  public personalButtons = [
    // { title: "WasGibtsNeues", url: '/news', photo: '../assets/icon/megaphone-outline.png' },
    // { title: "NeustartTutorial", url: '/dashboard', photo: '../assets/icon/tutorial.png' },
    // { title: 'HowToVideos', url: '/howto', photo: '../assets/icon/play-skip-forward-outline.png' },
    // { title: 'Hilfe', url: '/help', photo: '../assets/icon/help-circle-outline.png' },
    // { title: 'AccountEinstellungen', url: '/profile', photo: '../assets/icon/settings-outline.png' },
    { title: 'AccountEinstellungen', url: '/profile', icon: 'settings' },
  ];
  //
  @ViewChildren('itemRef', { read: ElementRef }) itemRefs: QueryList<ElementRef>;

  monaPlatform: boolean = true;
  monaWorkforce: boolean = true;
  monaSupport: boolean = false;
  businessName: string = "";
  lastLogin: any;
  currentItemName: string = "Dashboard";
  currentUserExample = {
    name: 'Jane Doe',
    profilePicture: '../assets/icon/megaphone-outline.png', // Leave empty to display initials
  };
  businessLogo: any; // base64
  businessNameOnlyFirstLetter: string = "";
  ngOnInit() {}

  ngAfterViewInit() {
    console.log("runs first")
    const admin = this.authService.returnUserRole();
    this.admin = admin;
    this.enterprise = true;
    this.adminBool = admin == "admin" ? true : false;
    console.log("admin", admin);
    this.itemRefs.forEach(itemRef => {
      if (itemRef.nativeElement.innerText == "Stellenanzeigen") {
        const currentPage = this.router.url;
        const node = itemRef.nativeElement;
        this.authService.setData(node);
        console.log(node)
      }
    });
    this.authService.isAuthenticated$.subscribe(isAuthenticated => {
      this.isAuthenticated = isAuthenticated;
      if (this.isAuthenticated) {
        this.chooseSubItem("Dashboard");
        document.getElementById("Dashboard").style.backgroundColor = "#F6F6F6";
        let userRole = this.authService.returnUserRole();
        console.log("userRole", userRole);
      }
      this.cdr.detectChanges();
    });
    // this.menuService.AccountSettings$.subscribe((settings) => {
    //   this.personalButtons = settings;
    // });
  }
  public menuCollapsed = false;

  // Variables for notifications
  newApplicantsCount = 10;        // Will store how many new applicants arrived
  newMatchesCount = 10;        // Will store how many new applicants arrived
  showNewApplicantsToast = true; // Controls if the popup/toast is shown
  lastApplicantsCount = 0;       // Keep track of old count to compare
  showGlobalBar = "false";         // Boolean to show the global bar
  newTutorials = false;          // Boolean to indicate new tutorials
  newIntegrations = false;      // Boolean to indicate new integrations

  constructor(
    private profileService: ProfileService,
    private tutorialService: TutorialService,
    private router: Router,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private firebaseService: FirebaseService,
    private menuService: MenuService,
    private customerDB: CustomerdatabaseService,
  ) {
    this.translationObject = TranslationObject.TRANSLATION_OBJECT;
    this.menuService.menuItems$.subscribe(async (items) => {
      this.appPages = items;
      this.getBusinessName();
    })
    this.menuService.userRole$.subscribe((plan) => {
      this.currentPlan = plan;
      console.log("currentPlan", this.currentPlan);
      if(this.currentPlan == "free" || this.currentPlan == "starter" || this.currentPlan == "wachstum") {
        this.showGlobalBar = "true";
      }
    })
    this.menuService.planLimits$.subscribe((plan) => {
      console.log("plan", plan);
      this.applicantsRemaining = plan.applicantsRemaining || 0;
      if(this.applicantsRemaining != undefined && this.applicantsRemaining > 0) {
        this.showGlobalBar = "true";
        console.log("applicantsRemaining", this.applicantsRemaining);
      } else {
        this.showGlobalBar = "false";
      }
      if(plan.planName == "agents") {
        this.showGlobalBar = "true";
      }
    })
    // timeout 10 seconds
    setTimeout(() => {
      this.creditsRefresh();
    }, 10000);
  }

  async logout() {
    try {
      document.cookie = `MONAPlatformToken=; path=/; max-age=0; Secure; SameSite=Strict`;
    } catch (err) {}
    localStorage.clear();
    await this.authService.logOut();
  }

  goToProfile() {
    this.router.navigateByUrl('/profile');
  }

  goToSettings() {
    this.router.navigateByUrl('/settings');
  }

  goToPricing() {
    this.router.navigateByUrl('/pricing');
  }

  private checkForNewMatches() {
    // this.tutorialService.getNewMatches().then((newMatches) => {
    //   this.newMatchesCount = newMatches;
    // });
    if (this.businessName == "") {
      return;
    }

    let milliseconds = this.lastLogin.seconds * 1000 + this.lastLogin.nanoseconds / 1000000;
    let queryDate = new Date(milliseconds);
    if(false) {
      this.firebaseService.returnSalesEmailsNormalCustomers(this.businessName)
      .get()
      .then((snap) => {
        let matches = snap.docs.map((doc) => {
          let data = doc.data();
          data.id = doc.id;
          return data;
        });
        console.log(matches);
        let newMatches = [];
        this.firebaseService.saveEmailsToBeSentLocally(matches);
        matches.forEach((match) => {
          if (match.created) {
            let created = new Date(match.created);
            if (created > queryDate) {
              newMatches.push(match);
            }
          }
        });
        this.newMatchesCount = newMatches.length;
        if (this.newMatchesCount > 0) {
          this.appPages.forEach(element => {
            if (element.title == "Kommunikation") {
              element.showNewBadge = true;
            }
          });
        }
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  private async listenToApplicants() {
    // get the date from last login
    // then get applicants with date "created" after last login
    // then set the newApplicantsCount
    let lastLogin = await this.firebaseService.returnLastLogin();
    console.log("lastLogin", lastLogin);
    let loginData = lastLogin.data().lastLogin;
    if (loginData == undefined || loginData == null) {
      this.firebaseService.updateLastLogin(this.authService.returnUserId());
      return;
    }
    console.log("lastLogin", loginData);
    let milliseconds = loginData.seconds * 1000 + loginData.nanoseconds / 1000000;
    let queryDate = new Date(milliseconds);
    console.log("queryDate", queryDate);
    this.customerDB.getApplicantsAfterDate(queryDate).get()
      .then((snap) => {
        let applicants = snap.docs.map((doc) => doc.data());
        this.newApplicantsCount = applicants.length;
        this.firebaseService.updateLastLogin(this.authService.returnUserId());
        console.log("newApplicantsCount", this.newApplicantsCount);
        if (this.newApplicantsCount > 0) {
          this.appPages.forEach(element => {
            if (element.title == "Kandidaten") {
              element.showNewBadge = true;
            }
          });
        }
      })
  }

  private checkForNewTutorials() {
    // this.tutorialService.getNewTutorials().then((newTutorials) => {
    //   this.newTutorials = newTutorials;
    // });
  }

  private checkForNewIntegrations() {
    // this.tutorialService.getNewIntegrations().then((newIntegrations) => {
    //   this.newIntegrations = newIntegrations;
    // });
  }

  public markApplicantsAsRead() {
    // If you want to update last login to “now,” do it here
    this.firebaseService.updateLastLogin(this.authService.returnUserId())
      .then(() => {
        console.log('Last login updated successfully.');
      })
      .catch((err) => {
        console.error('Error updating last login:', err);
      });

    // Reset the count after the user acknowledges them
    this.newApplicantsCount = 0;
  }

  public markMatchesAsRead() {
    this.firebaseService.markMatchesAsRead();
    this.newMatchesCount = 0;
  }

  creditsRefresh() {
    this.customerDB.retreiveUserCreditsAllTheTimeUpToDate((credits) => {
      console.log("User credits updated:", credits);
      this.creditsRemaining = credits;
    });
  }

  toggleMenu() {
    this.menuCollapsed = !this.menuCollapsed;
  }

  manageSubscription() {
    this.router.navigateByUrl('/pricing');
  }

  async getBusinessName() {
    this.businessName = this.customerDB.returnBusinessName();
    // get profile data logo
    this.customerDB.getProfile().get().then((data) => {
      if(data.docs.length == 0) {
        return;
      } else {
        console.log("Profile data found");
        let profileData = data.docs[0].data();
        this.businessLogo = "data:image/jpeg;base64," +profileData.companyLogo;
        console.log("businessLogo", this.businessLogo);
      }
    });
    console.log("businessName", this.businessName);
    console.log("businessLogo", this.businessLogo);
    this.businessNameOnlyFirstLetter = this.businessName.charAt(0);
    console.log("businessNameOnlyFirstLetter", this.businessNameOnlyFirstLetter);
    this.lastLogin = await this.getLastLogin();
    this.checkForNewMatches();
    this.listenToApplicants();
    this.checkForNewTutorials();
    this.checkForNewIntegrations();
    console.log("businessName", this.businessName);
  }

  async getLastLogin() {
    let lastLogin = await this.firebaseService.returnLastLogin();
    console.log("lastLogin", lastLogin);
    let loginData = lastLogin.data().lastLogin;
    if (loginData == undefined || loginData == null) {
      await this.firebaseService.updateLastLogin(this.authService.returnUserId());
      this.getLastLogin();
    }
    return loginData;
  }

  openMONASupportLink() {
    window.open("https://calendly.com/mona-vertrieb/mona-ai-onboarding-support");
  }

  openDocumentation() {
    window.open("https://docs.mona-ai.de/");
  }

  openMONAChat() {
    this.chooseSubItem('chatMonaBeta');
    this.router.navigateByUrl('/mona-agent-chat');
  }

  openAIAgents() {
    this.chooseSubItem('chatAgentenBeta');
    this.router.navigateByUrl('/agents');
  }

  dataImport() {
    this.router.navigateByUrl('/upload');
  }

  blacklist() {
    this.router.navigateByUrl('/blacklist');
  }

  integrations() {
    this.newIntegrations = false;
    this.router.navigateByUrl('/integrations');
  }


  getCurrentUser() {
    var currentUser = this.profileService.getCurrentUser();
    this.currentUser = currentUser;
    console.log(this.currentUser);
    if (this.currentUser.email != "admin@mona-ai.de") {
      this.appPages.splice(1, 6)
    }
    console.log(currentUser);
  }

  navigateToFeedbackUrl() {
    window.open("https://www.mona-ai.de/kontakt");
  }

  openYoutube() {
    window.open("https://www.youtube.com/channel/UCZUycJcsFxQAE52kaYi52xg");
  }

  openTutorials() {
    this.router.navigateByUrl('/video-library');
  }

  backToDashboard() {
    this.router.navigateByUrl("/dashboard");
  }

  updateMonaItemStyle(id: string) {

    
    // switch (id) {
    //   case "monaPlatformDiv":
    //     {
    //       document.getElementById("monaPlatformDiv").style.backgroundColor = "#EEEEEE";
    //       document.getElementById("monaWorkforceDiv").style.backgroundColor = "white";
    //       document.getElementById("monaSupportDiv").style.backgroundColor = "white";
    //     }
    //     break;

    //   case "monaWorkforceDiv":
    //     {
    //       document.getElementById("monaPlatformDiv").style.backgroundColor = "white";
    //       document.getElementById("monaWorkforceDiv").style.backgroundColor = "#EEEEEE";
    //       document.getElementById("monaSupportDiv").style.backgroundColor = "white";
    //     }
    //     break;

    //   case "monaSupportDiv":
    //     {
    //       document.getElementById("monaPlatformDiv").style.backgroundColor = "white";
    //       document.getElementById("monaWorkforceDiv").style.backgroundColor = "white";
    //       document.getElementById("monaSupportDiv").style.backgroundColor = "#EEEEEE";
    //     }
    //     break;

    //   default:
    //     break;
    // }
  }

  switchMonaPlatform() {
    this.monaPlatform = !this.monaPlatform;
    // if (this.monaPlatform) {
    //   this.monaWorkforce = false;
    //   this.monaSupport = false;
    // }
    // this.updateMonaItemStyle("monaPlatformDiv");
  }

  switchMonaWorkforce() {
    this.monaWorkforce = !this.monaWorkforce;
    // if (this.monaWorkforce) {
    //   this.monaPlatform = false;
    //   this.monaSupport = false;
    // }
    // this.updateMonaItemStyle("monaWorkforceDiv");
  }

  switchMonaSupport() {
    this.monaSupport = !this.monaSupport;
    // if (this.monaSupport) {
    //   this.monaPlatform = false;
    //   this.monaWorkforce = false;
    // }
    // this.updateMonaItemStyle("monaSupportDiv");
  }

  mouseenter(name: string, monaDiv: HTMLDivElement) {
    switch (name) {
      case "MONA Platform":
        monaDiv.style.backgroundColor = "#F6F6F6";
        break;
      case "MONA Workforce":
        monaDiv.style.backgroundColor = "#F6F6F6";
        break;
      case "Support":
        monaDiv.style.backgroundColor = "#F6F6F6";
        break;

      default:
        break;
    }
  }

  mouseleave(name: string, monaDiv: HTMLDivElement) {
    switch (name) {
      case "MONA Platform":
        monaDiv.style.backgroundColor = "white";
        break;
      case "MONA Workforce":
        monaDiv.style.backgroundColor = "white";
        break;
      case "Support":
        monaDiv.style.backgroundColor = "white";
        break;

      default:
        break;
    }
  }

  appmouseenter(name: string) {
    document.getElementById(name).style.backgroundColor = "#EEEEEE";
  }

  appmouseleave(name: string) {
    if (name == this.currentItemName) {
      document.getElementById(name).style.backgroundColor = "#F6F6F6";
    }
    else {
      document.getElementById(name).style.backgroundColor = "white";
    }
  }

  settingmouseenter(name: string) {
    document.getElementById(name).style.backgroundColor = "#072966";
  }

  settingmouseleave(name: string) {
    document.getElementById(name).style.backgroundColor = "#151945";
  }

  chooseSubItem(name: string) {
    console.log("name", name);
    this.currentItemName = name;

    // this.appPages.forEach(element => {
    //   if (element.title == name) {
    //     document.getElementById(element.title).style.backgroundColor = "#EEEEEE";
    //   }
    //   else {
    //     document.getElementById(element.title).style.backgroundColor = "white";
    //   }
    // });

    // let menuEntry = this.appPages.find(element => element.title == name)
    // if (menuEntry != undefined) {
    //   menuEntry.showNewBadge = false;
    // }

    // if (name == "chatMonaBeta") {
    //   document.getElementById("chatMonaBeta").style.backgroundColor = "#EEEEEE";
    // }
    // else {
    //   document.getElementById("chatMonaBeta").style.backgroundColor = "white";
    // }

    // if (name == "chatAgentenBeta") {
    //   document.getElementById("chatAgentenBeta").style.backgroundColor = "#EEEEEE";
    // }
    // else {
    //   document.getElementById("chatAgentenBeta").style.backgroundColor = "white";
    // }

    // if (name == "Tutorials") {
    //   document.getElementById("Tutorials").style.backgroundColor = "#EEEEEE";
    // }
    // else {
    //   document.getElementById("Tutorials").style.backgroundColor = "white";
    // }
  }
}


