import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth} from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { Observable, from, of, BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { map, shareReplay } from 'rxjs/operators';

export interface User {
  created: any;
  email: string;
  name: string;
  firstName: string;
  lastName: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  windowRef: any;
  currentUser: any;
  userId = "";
  hasVerifiedEmail = false;
  prefix: any;
  line: any;
  verifCode: any;
  user: Observable<User>;
  public user$: Observable<any> = this.afAuth.user.pipe(shareReplay(1));
  Useremail = "";
  userData: any;
  userRole = "";
  private data = new BehaviorSubject<string>('');
  customerNumber = 0;
  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject.asObservable();
  accountId:string = "";
  // user Plan with behaviur subject
  private userPlan = new BehaviorSubject<string>('');
  userPlan$ = this.userPlan.asObservable();
  private licenses = 0;

  constructor(
    private afAuth: AngularFireAuth,
    private db: AngularFirestore,
    private router: Router,
    private ngZone: NgZone
    // private afAuth: AngularFireAuth
  ) {
    this.user$.subscribe();
    this.user = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          this.currentUser = user.uid;
          console.log("this.currentUser", this.currentUser);
          return this.db.doc(`customers/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    )
    // this.afAuth.authState.subscribe(user => {
    //   if (user)
    //   console.log(user);
    //   this.currentUser = user;
    //     this.hasVerifiedEmail = this.afAuth.auth.currentUser.emailVerified;
    // });
    //
  }

  returnUser() {
    return this.Useremail
  }

  saveEmail(email) {
    this.Useremail = email;
  }

  saveUserId(uid) {
    this.userId = uid;
    console.log("this.userId", this.userId);
  }

  saveAccountId(accountId) {
    this.accountId = accountId;
  }

  saveLicenses(licenses) {
    this.licenses = licenses;
  }

  returnLicenses() {
    return this.licenses;
  }

  returnUserId() {
    return this.userId;
  }

  returnAccountId() {
    return this.accountId;
  }

  saveKundennummer(kundennummer) {
    this.customerNumber = kundennummer;
  }

  returnCustomerNumber() {
    return this.customerNumber;
  }

  saveUserRole(role) {
    this.userRole = role;
  }

  returnUserRole() {
    return this.userRole;
  }

  signIn(credentials): Observable<any> {
    return from(this.afAuth.signInWithEmailAndPassword(credentials.email, credentials.password)).pipe(
      switchMap(user => {
        if (user) {
          this.isAuthenticatedSubject.next(true);
          return this.db.doc(`customers/${user.user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    )
  }

  signUp(credentials) {
    return this.afAuth.createUserWithEmailAndPassword(credentials.email, credentials.password).then(data => {
      return this.db.doc(`customers/${data.user.uid}`).set({
        name: credentials.name,
        email: credentials.email,
        created: firebase.firestore.FieldValue.serverTimestamp()
      });
    });
  }

  sendPasswordReset(email) {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  async logOut() {
    await this.afAuth.signOut().then(() => {
      window.location.href = `${window.location.origin}/login`;
    });
  }

  isLoggedIn(): boolean {
    return this.isAuthenticatedSubject.value;
  }

  updateUser(name) {
    return this.db.doc(`customers/${firebase.auth().currentUser.uid}`).update({
      name
    });
  }

  loginUser(email: string, password: string): Promise<firebase.auth.UserCredential> {
    this.isAuthenticatedSubject.next(true);
    return firebase.auth().signInWithEmailAndPassword(email, password)
  }

  updateIsFirstLoginFlag(id: string) {
    const userRef = this.db.collection('customers').doc(id);
    userRef.update({ isFirstLogin: false });
  }

  updateIsFirstLoginFlagToTrue(id: string) {
    const userRef = this.db.collection('customers').doc(id);
    userRef.update({ isFirstLogin: true });
  }

  // getIsFirstLoginFlag(uid: string) {
  //   const customerDoc = this.db.collection('customers').doc(uid);
  //   const isFirstLogin$ = customerDoc.get().pipe(
  //     switchMap(doc => {
  //       const data = doc.data();
  //       if (data.isFirstLogin == undefined) {
  //         return from(customerDoc.set({ isFirstLogin: true }, { merge: true })).pipe(
  //           map(() => true)
  //         );
  //       } else {
  //         return of(data.isFirstLogin);
  //       }
  //     })
  //   );
  //   return isFirstLogin$;
  // }

  resetPassword(email: string): Promise<void> {
    return firebase.auth().sendPasswordResetEmail(email);
  }

  logoutUser(): Promise<void> {
    return firebase.auth().signOut();
  }

  setPersistence() {
    return firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  }

  loginWithToken(token: string): Promise<firebase.auth.UserCredential> {
    this.isAuthenticatedSubject.next(true);
    return firebase.auth().signInWithCustomToken(token);
  }

  verifySessionCookie(cookie) {
    this.isAuthenticatedSubject.next(cookie);
  }

  getLocalToken() {
    firebase.auth().currentUser?.getIdToken().then((token) => {
      document.cookie = `firebaseAuthToken=${token}; path=/; max-age=3600; Secure; SameSite=Strict`;
  });  
}

  signupUser(email: string, password: string, firstName?: string, lastName?: string): Promise<string> {
    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((newUserCredential: firebase.auth.UserCredential) => {
        return newUserCredential.user.uid;
      })
      .catch(error => {
        console.error(error);
        throw error;
      });
  }

  // signupUser(email: string, password: string, firstName?: string, lastName?: string): Promise<void> {
  //   return firebase
  //     .auth()
  //     .createUserWithEmailAndPassword(email, password)
  //     .then((newUserCredential: firebase.auth.UserCredential) => {
  //       firebase
  //         .firestore()
  //         .doc(`/customers/${newUserCredential.user.uid}`)
  //         .set(
  //           {
  //             email: email,
  //             firstName: firstName || "",
  //             lastName: lastName || "",
  //             created: new Date(),
  //             createdISO: new Date().toISOString().split("T")[0],
  //             createdISOTime: new Date().toISOString().split("T")[1],
  //             createdISODateTime: new Date().toISOString()
  //           }
  //         );
  //     })
  //     .catch(error => {
  //       console.error(error);
  //       throw new Error(error);
  //     });
  // }

  signInWithGoogle() {
    return this.afAuth
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then((res) => {
        console.log('AuthService::Successful Google login', res);
        return this.ngZone.run(() => this.router.navigate(['/']));
      })
      .catch((err) => {
        console.log('AuthService::Failed Google login', err);
      });
  }

  // googleLogin(token: string): Observable<any> {
  //   return this.http.post<any>(`${this.baseUrl}/google-login`, { token });
  // }

  signInWithGithub() {
    return this.afAuth
      .signInWithPopup(new firebase.auth.GithubAuthProvider())
      .then((res) => {
        console.log('AuthService::Successful Github login', res);
        return this.ngZone.run(() => this.router.navigate(['/']));
      })
      .catch((err) => {
        console.log('AuthService::Failed Github login', err);
      });
  }

  setData(data: string) {

    this.data.next(data);
    data = "";
  }

  getData() {
    return this.data.asObservable();
  }

}
