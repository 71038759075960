import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
import { Observable, from, of, BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { map, shareReplay } from 'rxjs/operators';

export interface User {
  created: any;
  email: string;
  name: string;
  firstName: string;
  lastName: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private smallBatchDevsEmail = 'smallbatchdevs@gmail.com';
  windowRef: any;
  currentUser: any;
  hasVerifiedEmail = false;
  prefix: any;
  line: any;
  verifCode: any;
  user: Observable<User>;
  public user$: Observable<any> = this.afAuth.user.pipe(shareReplay(1));
  Useremail = "";
  userData: any;

  private data = new BehaviorSubject<string>('');

  private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject.asObservable();

  constructor(
    private afAuth: AngularFireAuth,
    private db: AngularFirestore,
    private router: Router,
    private ngZone: NgZone
    // private afAuth: AngularFireAuth
  ) {
    this.user$.subscribe();
    this.user = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          this.currentUser = user.uid;
          console.log("this.currentUser", this.currentUser);
          return this.db.doc(`customers/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    )
    // this.afAuth.authState.subscribe(user => {
    //   if (user)
    //   console.log(user);
    //   this.currentUser = user;
    //     this.hasVerifiedEmail = this.afAuth.auth.currentUser.emailVerified;
    // });
    //
  }

  returnUser() {
    return this.Useremail
  }

  saveEmail(email) {
    this.Useremail = email;
  }

  signIn(credentials): Observable<any> {
    return from(this.afAuth.signInWithEmailAndPassword(credentials.email, credentials.password)).pipe(
      switchMap(user => {
        if (user) {
          this.isAuthenticatedSubject.next(true);
          return this.db.doc(`customers/${user.user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    )
  }

  signUp(credentials) {
    return this.afAuth.createUserWithEmailAndPassword(credentials.email, credentials.password).then(data => {
      return this.db.doc(`customers/${data.user.uid}`).set({
        name: credentials.name,
        email: credentials.email,
        created: firebase.firestore.FieldValue.serverTimestamp()
      });
    });
  }

  sendPasswordReset(email) {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  async logOut() {
   await this.afAuth.signOut().then(() => {
      window.location.href = `${window.location.origin}/login`;
    });
  }

  isLoggedIn(): boolean {
    return this.isAuthenticatedSubject.value;
  }

  updateUser(name) {
    return this.db.doc(`customers/${firebase.auth().currentUser.uid}`).update({
      name
    });
  }

  loginUser(email: string, password: string): Promise<firebase.auth.UserCredential> {
    this.isAuthenticatedSubject.next(true);
    return firebase.auth().signInWithEmailAndPassword(email, password)
  }

  updateIsFirstLoginFlag(id: string) {
    const userRef = this.db.collection('customers').doc(id);
    userRef.update({ isFirstLogin: false });
  }

  updateIsFirstLoginFlagToTrue(id: string) {
    const userRef = this.db.collection('customers').doc(id);
    userRef.update({ isFirstLogin: true });
  }

  // getIsFirstLoginFlag(uid: string) {
  //   const customerDoc = this.db.collection('customers').doc(uid);
  //   const isFirstLogin$ = customerDoc.get().pipe(
  //     switchMap(doc => {
  //       const data = doc.data();
  //       if (data.isFirstLogin == undefined) {
  //         return from(customerDoc.set({ isFirstLogin: true }, { merge: true })).pipe(
  //           map(() => true)
  //         );
  //       } else {
  //         return of(data.isFirstLogin);
  //       }
  //     })
  //   );
  //   return isFirstLogin$;
  // }

  resetPassword(email: string): Promise<void> {
    return firebase.auth().sendPasswordResetEmail(email);
  }

  logoutUser(): Promise<void> {
    return firebase.auth().signOut();
  }

  sendVerificationEmail(email) {
    // firebase.auth().sendEmailVerification();
    var actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: 'https://bps-mitarbeiter.web.app/emailverify/email/' + email,
      handleCodeInApp: true
    };
    firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings).then((snap) => {
      console.log(snap);
    }).catch((err) => {
      console.log(err);
    })
  }

  signupUser(email: string, password: string, firstName?: string, lastName?: string): Promise<void> {
    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((newUserCredential: firebase.auth.UserCredential) => {
        firebase
          .firestore()
          .doc(`/customers/${newUserCredential.user.uid}`)
          .set(
            {
              email: email,
              firstName: firstName || "",
              lastName: lastName || "",
              created: new Date(),
              createdISO: new Date().toISOString().split("T")[0],
              createdISOTime: new Date().toISOString().split("T")[1],
              createdISODateTime: new Date().toISOString()
            }
          );
      })
      .catch(error => {
        console.error(error);
        throw new Error(error);
      });
  }

  signInWithGoogle() {
    return this.afAuth
      .signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then((res) => {
        console.log('AuthService::Successful Google login', res);
        return this.ngZone.run(() => this.router.navigate(['/']));
      })
      .catch((err) => {
        console.log('AuthService::Failed Google login', err);
      });
  }

  signInWithGithub() {
    return this.afAuth
      .signInWithPopup(new firebase.auth.GithubAuthProvider())
      .then((res) => {
        console.log('AuthService::Successful Github login', res);
        return this.ngZone.run(() => this.router.navigate(['/']));
      })
      .catch((err) => {
        console.log('AuthService::Failed Github login', err);
      });
  }

  isSmallBatchDevsLoggedIn$(): Observable<boolean> {
    return this.user$.pipe(map(user => user && user.email === this.smallBatchDevsEmail));
  }

  setData(data: string) {

    this.data.next(data);
    data = "";
  }

  getData() {
    return this.data.asObservable();
  }

}
