import { Injectable } from '@angular/core';
import firebase from "firebase/app";
import { CustomerdatabaseService, FILE } from './customerdatabase.service';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { FirebaseConstants } from '../shared/FirebaseConstants';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  public bewerber: firebase.firestore.CollectionReference;
  public bewerbervertraege: firebase.firestore.CollectionReference;
  public bewerberVertraegeColl: firebase.firestore.CollectionReference;
  public korrespondenz: firebase.firestore.CollectionReference;
  public massenanruf: firebase.firestore.CollectionReference;
  public companies: firebase.firestore.CollectionReference;
  public workflows: firebase.firestore.CollectionReference;
  public salesmails: firebase.firestore.CollectionReference;
  public howTos: firebase.firestore.CollectionReference;
  public supporttickets: firebase.firestore.CollectionReference;
  public cvs: firebase.firestore.CollectionReference;
  public vertriebkorrespondenzen: firebase.firestore.CollectionReference;
  public indexanzeigen: firebase.firestore.CollectionReference;
  public users: firebase.firestore.CollectionReference;
  public verifiedNumbers: firebase.firestore.CollectionReference;
  public customerCompanies: firebase.firestore.CollectionReference;
  public updates: firebase.firestore.CollectionReference;
  newContractId = "";
  emailsToBeSent: any;
  constructor(
    private customerDB: CustomerdatabaseService,
    private storage: AngularFireStorage,
    private authService: AuthService
  ) {
    this.bewerber = firebase.firestore().collection("bewerber");
    this.bewerbervertraege = firebase.firestore().collection("kunden");
    this.bewerberVertraegeColl = firebase.firestore().collection("bewerberVertraege");
    this.korrespondenz = firebase.firestore().collection("korrespondenz");
    this.massenanruf = firebase.firestore().collection("massenanruf");
    this.companies = firebase.firestore().collection("companies");
    this.workflows = firebase.firestore().collection("workflows");
    this.salesmails = firebase.firestore().collection("salesmails");
    this.users = firebase.firestore().collection("customers");
    this.howTos = firebase.firestore().collection(FirebaseConstants.howtos);
    this.supporttickets = firebase.firestore().collection("supporttickets");
    this.cvs = firebase.firestore().collection("cvanalysen");
    this.indexanzeigen = firebase.firestore().collection("indexanzeigen");
    this.vertriebkorrespondenzen = firebase.firestore().collection("profilvertriebKorrespondenz");
    this.verifiedNumbers = firebase.firestore().collection("verifiedNumbers");
    this.customerCompanies = firebase.firestore().collection("customerCompanies");
    this.updates = firebase.firestore().collection("updates");
  }

  bewerberVertraege(file, id) {
    return this.bewerberVertraegeColl.doc(id).set({
      id: id,
      file: file,
      created: new Date(),
      createdGerman: (new Date()).toLocaleDateString("de-DE")
    })
  }

  getUserPermissions(uid: string): firebase.firestore.DocumentReference {
    return this.users.doc(uid);
  }

  getUserPermissionByEmail(email: string) {
    return this.users.where("email", "==", email).get();
  }

  saveUserPermissions(id, permissions) {
    return this.users.doc(id).update({
      permissions: permissions
    });
  }

  getApplicant(): firebase.firestore.CollectionReference {
    return this.bewerber;
  }

  returnUsers(): firebase.firestore.CollectionReference {
    return this.users;
  }
  
  addVerifiedNumbers(phoneNumber, generatedNumber) {
    return this.verifiedNumbers.add({
      phoneNumber: phoneNumber,
      accountId: this.authService.returnAccountId(),
      confirmed: false,
      generatedNumber: generatedNumber,
      userId: this.authService.returnUserId()
    });
  }

  returnVerifiedPhoneNumbers() {
    return this.verifiedNumbers.where("userId", "==", this.authService.returnUserId()).get();
  }

  returnSpecificVerifiedPhoneNumber(phoneNumber) {
    return this.verifiedNumbers
    .where("phoneNumber", "==", phoneNumber)
    .where("userId", "==", this.authService.returnUserId())
    .get();
  }

  getContract(id): firebase.firestore.DocumentReference {
    return firebase.firestore().collection(FirebaseConstants.contracts).doc(id)
  }

  getNewContractId() {
    return this.newContractId;
  }

  getCompanies() {
    return firebase.firestore().collection(FirebaseConstants.companies)
  }

  getCustomerCompanies(domain): firebase.firestore.DocumentReference {
    return this.customerCompanies.doc(domain)
  }

  getSupportTickets() {
    return firebase.firestore().collection("supporttickets")
  }

  getdistances() {
    return firebase.firestore().collection("distances")
  }

  saveDistanceCity(distance, residence, company, type) {
    console.log(distance, residence, company, type)
    return firebase.firestore().collection("distances").add({
      distance: distance,
      residence: residence,
      residencecity: residence.city,
      company: company,
      companycity: company.city,
      type: type,
      created: new Date(),
      createdGerman: (new Date()).toLocaleDateString("de-DE")
    })
  }

  saveDistance(distance, residence, company, type) {
    console.log(distance, residence, company, type)
    return firebase.firestore().collection("distances").add({
      distance: distance,
      residence: residence,
      residencezip: residence.postleitzahl,
      residencecity: residence.city,
      residencestreet: residence.strasse,
      residencehousenumber: residence.hausnummer,
      company: company,
      companyzip: company.postleitzahl,
      companycity: company.city,
      companystreet: company.strasse,
      companyhousenumber: company.hausnummer,
      type: type,
      created: new Date(),
      createdGerman: (new Date()).toLocaleDateString("de-DE")
    })
  }

  addSupportTicket(ticket) {
    return firebase.firestore().collection("supporttickets").add(ticket)
  }

  storeAICVFeedbackForMatching(id, feedback) {
    return firebase.firestore().collection("cvAIMatchingAnalysis").add({
      feedbackForMatching: feedback,
      created: new Date(),
      isFeedbackForMatching: true,
      createdISODate: new Date().toISOString(),
      createdISO: new Date().toISOString().slice(0, 10)
    })
  }

  addApplicantCVQualifications(data) {
    return firebase.firestore().collection("cvanalysen").add({
      cvQualifications: data,
      cvAnalyzed: true,
      created: new Date(),
      status: "aktiv",
      createdISO: new Date().toISOString(),
      createdISODate: new Date().toISOString().split('T')[0],
      createdGerman: new Date().toLocaleString('de-DE')
    })
  }

  storeFilesFirebase(image: FILE) {
    console.log(image);
    // const fileId = this.app2.createId();
    // console.log(fileId);
    firebase.firestore().collection(FirebaseConstants.contracts)
      .add(image)
      .then((res) => {
        this.newContractId = res.id;
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getSalesEmails() {
    return this.salesmails;
  }

  getImageRef(fileStoragePath) {
    return this.storage.ref(fileStoragePath);
  }

  getUploadTask(fileStoragePath, file) {
    return this.storage.upload(fileStoragePath, file);
  }

  getSalesmails(): firebase.firestore.CollectionReference {
    return this.salesmails;
  }

  getApplicantDetail(id): firebase.firestore.DocumentReference {
    return this.bewerber.doc(id)
  }

  setJoinApiKey(joinapikey) {
    const customerNumber = this.customerDB.getCustomerNumber();
    this.companies
      .where(FirebaseConstants.kundennummer, "==", customerNumber)
      .get()
      .then((snap) => {
        console.log(snap);
        var id = snap.docs[0].id;
        this.companies.doc(id).update({
          joinapikey: joinapikey
        })
      });
  }

  createApplicant(form) {
    this.getApplicant()
      .orderBy("id", "desc")
      .get()
      .then((snap) => {
        var id = String(Number(snap.docs[0].id) + 1);
        return this.bewerber.doc(id).set({
          form: form,
          email: form.email,
          id: id
        }).then(() => {
        })
      }).catch((err) => {
        var id = "no id";
        return id
      })
  }

  updateApplicantForm(id, form) {
    console.log(form);
    return this.bewerber.doc(id).update({
      form: form
    });
  }

  updateBewerberStatus(id, pn, arbeit, form) {
    console.log(form);
    return this.bewerber.doc(id).update({
      status: "mitarbeiter",
      personalnummer: pn,
      Personalnummer: pn,
      Arbeit: arbeit,
      arbeit: arbeit,
      form: form
    });
  }

  getPicklistApplicantContracts(): firebase.firestore.CollectionReference {
    return this.bewerbervertraege
  }

  SalesAnlegen(item, email, vorlage, url, anrede) {
    return this.salesmails.add({
      customer: item,
      email: email,
      vorlage: vorlage,
      url: url,
      anrede: anrede,
      created: new Date(),
      createdGerman: (new Date()).toLocaleDateString("de-DE"),
      createdISO: (new Date()).toISOString(),
      createdISODate: (new Date()).toISOString().split("T")[0]
    })
  }

  Korrespondenzanlegen(id, art, status, date, isodate, user, message?) {
    console.log(id)
    console.log(status)
    console.log(date)
    console.log(isodate)
    return this.korrespondenz.doc(id).collection("korrespondenz").add({
      art: art,
      date: date,
      IsoDate: isodate,
      user: user,
      status: status,
      message: message || ""
    })
  }

  KorrespondenzExport(id, art, status, date, isodate, user) {
    return this.massenanruf.add({
      art: art,
      date: date,
      IsoDate: isodate,
      user: user,
      status: status
    })
  }

  getAllHowTos(): firebase.firestore.CollectionReference {
    console.log(this.howTos);
    return this.howTos;
  }

  getIndexAnzeigenStellen(): firebase.firestore.CollectionReference {
    return this.indexanzeigen;
  }

  createIndexForResumes() {
    const customerAdminEmail = this.customerDB.returnCustomerAdminEmailAdress();
    const customerNumber = this.customerDB.getCustomerNumber();
    return new Promise((resolve, reject) => {
      this.companies.where(FirebaseConstants.kundennummer, "==", customerNumber).get().then((snap) => {
        if (snap.docs.length > 0) {
          snap.docs.forEach((obj) => {
            var data = obj.data();
            if (data.indexForResumes == undefined) {
              this.companies.doc(obj.id).update({
                indexForResumes: customerAdminEmail
              })
              var item = {
                email: customerAdminEmail,
                kundennummer: customerNumber,
                new: true
              }
              resolve(item); // new
            } else {
              var item = {
                email: customerAdminEmail,
                kundennummer: customerNumber,
                new: false
              }
              resolve(item); // old
            }
          })
        }
      })
    })
  }

  authenticatephoneNumber(phoneNumber, accountId) {

  }

  getIndexForResumes() {
    const customerNumber = this.customerDB.getCustomerNumber();
    return new Promise((resolve, reject) => {
      this.companies.where(FirebaseConstants.kundennummer, "==", customerNumber).get().then((snap) => {
        if (snap.docs.length > 0) {
          snap.docs.forEach((obj) => {
            resolve(obj.data().indexForResumes);
          })
        }
      })
    });
  }

  getPlanLimits(): Promise<any> {
    const customerNumber = this.customerDB.getCustomerNumber();
    return new Promise((resolve, reject) => {
      this.companies.where("kundennummer", "==", customerNumber).get().then((snap) => {
        if (snap.docs.length > 0) {
          snap.docs.forEach((obj) => {
            resolve(obj.data().planLimits);
          })
        }
      })
    });
  }

  getProfilvertriebKorrespondenz(businessName) {
    return this.vertriebkorrespondenzen.doc(businessName).collection("kaltakquise")
  }

  returnSentEmailsLast14Days(businessName) {
    // chose by createdISO 
    let fourteenDaysAgo = new Date();
    fourteenDaysAgo.setDate(fourteenDaysAgo.getDate() - 14);
    return this.vertriebkorrespondenzen.doc(businessName).collection("kaltakquise")
    .where("created", ">=", fourteenDaysAgo)
  }

  createprofilvertriebKorrespondenz(businessName, data) {
    return this.vertriebkorrespondenzen.doc(businessName).collection("kaltakquise").add({
      feedbackObject: data.feedbackObject,
      resJob: data.resJob || {},
      logs: data.logs,
      jobTitle: data.jobTitle,
      profileURL: data.profileURL,
      email: data.email,
      profileType: data.profileType,
      businessName: businessName,
      acquisitionSubject: data.acquisitionSubject,
      htmlbody: data.htmlbody,
      applicantId: data.applicantId,
      createdTimestamp: (new Date()).getTime(),
      created: new Date(),
      createdISO: (new Date()).toISOString(),
      status: "open",
      statusNumber: 1
    })
  }

  // not for public use
  getCVs(): firebase.firestore.CollectionReference {
    return this.cvs;
  }

  getUserEMail(): string {
    return this.authService.returnUser();
  }

  getUserProfile() {

  }

  getapplicantsformatching() {
    return firebase.firestore().collection("applicantsformatching")
  }

  // stripe
  createCheckoutSessionSubscription(userId: string, priceId: string, quantity: number) {
    return firebase.firestore()
      .collection('customers')
      .doc(userId)
      // .doc("p3eYZdu3E7QQYns8Bi6ZEUEK1B42")
      .collection('checkout_sessions')
      .add({
        price: priceId,  // Your Stripe Price ID 20 bugs
        success_url: window.location.origin + '/success',
        cancel_url: window.location.origin + '/cancel',
        mode: 'subscription',  // or 'payment' for one-time payments
        quantity: quantity,
      });
  }

  // one time payment 
  // createCheckoutSessionOneTimePayment(userId: string) {
  //   return firebase.firestore()
  //     .collection('customers')
  //     // .doc(userId)
  //     .doc("p3eYZdu3E7QQYns8Bi6ZEUEK1B42")
  //     .collection('checkout_sessions')
  //     .add({
  //       price: 'price_1QE9X6FrMBtw8WvfbBPKTTsn',  // Your Stripe Price ID
  //       success_url: window.location.origin + '/success',
  //       cancel_url: window.location.origin + '/cancel',
  //       mode: 'subscription',  // or 'payment' for one-time payments
  //       quantity: 1,
  //     });
  // }
  getUnsentEmailsCount(): firebase.firestore.DocumentReference {
    return firebase.firestore().collection('metadata').doc('emailCounters')
  }

  saveEmailsToBeSentLocally(emails) {
    this.emailsToBeSent = emails;
  }

  returnEmailsToBeSentLocally() {
    return this.emailsToBeSent;
  }

  returnSalesEmailsNormalCustomers(businessName): firebase.firestore.CollectionReference {
    return firebase.firestore().collection("emailsToBeSentGeneral").doc(businessName).collection("emailsToBeSent")
  }

  updateEmailsToBeSentTrueSparkasse(id) {
    return firebase.firestore().collection("emailsToBeSentSparkasse").doc(id).update({
      sent: false,
      sentDate: new Date(),
      user: this.authService.returnUser(),
      sentISODate: new Date().toISOString()
    })
  }

  updateEmailsToBeSentTrueProserv(id) {
    return firebase.firestore().collection("emailsToBeSent").doc(id).update({
      sent: false,
      sentDate: new Date(),
      user: this.authService.returnUser(),
      sentISODate: new Date().toISOString()
    })
  }

  updateEmailsToBeSentTrue(businessName, id) {
    return firebase.firestore().collection("emailsToBeSentGeneral").doc(businessName)
    .collection("emailsToBeSent").doc(id).update({
      sent: false,
      sentDate: new Date(),
      user: this.authService.returnUser(),
      sentISODate: new Date().toISOString()
    })
  }

  returnSalesEmailsNormalCustomersSinceLastLogin(businessName, lastLogin) {
    return firebase.firestore().collection("emailsToBeSentGeneral").doc(businessName)
    .collection("emailsToBeSent").where("created", ">", lastLogin)
  }

  pullAdditionalInfoOnEmailToBeSent(businessName): firebase.firestore.CollectionReference {
    return firebase.firestore().collection("emailsToBeSentGeneralWithSeparatedData")
    .doc(businessName).collection("emailsToBeSent")
  }

  pullAdditionalInfoOnEmailToBeSentDetailsByIdWithDocumentReference(businessName, id): firebase.firestore.DocumentReference {
    return firebase.firestore().collection("emailsToBeSentGeneralWithSeparatedData")
    .doc(businessName).collection("emailsToBeSent").doc(id)
  }

  returnSalesEmails(): firebase.firestore.CollectionReference {
    return firebase.firestore().collection("emailsToBeSent")
  }

  returnSalesEmailsSparkasse(): firebase.firestore.CollectionReference {
    return firebase.firestore().collection("emailsToBeSentSparkasse")
  }

  deleteSalesEmailsSparkasse(id) {
    return firebase.firestore().collection("emailsToBeSentSparkasse").doc(id).delete()
  }

  deleteSalesEmailsGeneral(id, businessName) {
    return firebase.firestore().collection("emailsToBeSentGeneral").doc(businessName)
    .collection("emailsToBeSent").doc(id).delete()
  }

  deleteSalesEmails(id) {
    return firebase.firestore().collection("emailsToBeSent").doc(id).delete()
  }

  updateEmailContentToBeSent(id, htmlbody) {
    return firebase.firestore().collection("emailsToBeSent").doc(id).update({
      htmlbody: htmlbody
    })
  }

  getProducts() {
    return firebase.firestore().collection('products');
  }

  getPrices(productId: string) {
    return firebase.firestore().collection('products').doc(productId).collection("prices");
  }

  sendSalesEmails(id) {
    return firebase.firestore().collection("emailsToBeSent").doc(id)
    .update({
      sent: true,
      sentDate: new Date(),
      user: this.authService.returnUser(),
      sentISODate: new Date().toISOString()
    })
  }

  sendSalesEmailsSparkasse(id) {
    return firebase.firestore().collection("emailsToBeSentSparkasse").doc(id)
    .update({
      sent: true,
      sentDate: new Date(),
      user: this.authService.returnUser(),
      sentISODate: new Date().toISOString()
    })
  }

  generateMONAIMAPAccount(brandingInfo) {
    return firebase.firestore().collection("MONAIMAPAccounts").add({
      brandingInfo: brandingInfo,
      created: new Date(),
      createdISO: new Date().toISOString(),
      createdISODate: new Date().toISOString().split('T')[0]
    })
  }

  sendSalesEmailsGeneral(id, businessName) {
    return firebase.firestore().collection("emailsToBeSentGeneral").doc(businessName)
    .collection("emailsToBeSent").doc(id)
    .update({
      sent: true,
      sentDate: new Date(),
      user: this.authService.returnUser(),
      sentISODate: new Date().toISOString()
    })
  }

  updateLastLogin(uid) {
    return firebase.firestore().collection("customers").doc(uid).update({
      lastLogin: new Date(),
      lastLoginISO: new Date().toISOString(),
      lastLoginGermanDate: new Date().toLocaleDateString("de-DE")
    })
  }

  returnLastLogin() {
    let uid = this.authService.returnUserId();
    return firebase.firestore().collection("customers").doc(uid).get();
  }

  markMatchesAsRead() {
    let userId = this.authService.returnUserId();
    return firebase.firestore().collection("customers").doc(userId).update({
      matchesReadDate: new Date()
    })
  }

  /**
   * Function to save the video generation information for a particular customer in the database (mona-pwa-firebase)
   * 
   * @author Chandrika
   * 
   * @param jobOfferId 
   * @param customerName 
   * @param lengthOfVideosGenerated 
   * @param avatarId 
   * @param languagesArray 
   * @returns 
   */

  saveVideoGenerationInformationForParticularCustomer(jobOfferId, customerName, lengthOfVideosGenerated, avatarId, languagesArray) {
    return firebase.firestore().collection("customerVideoGenerationInfo").add({
      jobOfferId: jobOfferId,
      customerName: customerName,
      lengthOfVideosGenerated: lengthOfVideosGenerated,
      avatarId: avatarId,
      languagesArray: languagesArray,
      created: new Date()
    })
  }

  saveVerificationToken(email, token) {
    return firebase.firestore().collection(FirebaseConstants.customers)
      .where('email', '==', email)
      .get()
      .then((snap) => {
        console.log("snap", snap);
        snap.forEach((doc) => {
          firebase.firestore().collection(FirebaseConstants.customers).doc(doc.id).update({
            emailVerificationToken: token
          });
        });
      });
  }


  // agents API
  getProfile():any {
    return firebase.firestore().collection(FirebaseConstants.branding);
  }

  verifyEmail(token: string) {
    return firebase.firestore().collection(FirebaseConstants.customers)
      .where('emailVerificationToken', '==', token)
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          firebase.firestore().collection(FirebaseConstants.customers).doc(doc.id).update({
            emailVerified: true,
            emailVerificationToken: null
          });
        });
      });
  }

  updateUserPlan(plan) {
    let userEmail = this.authService.returnUser();
    return firebase.firestore().collection(FirebaseConstants.customers)
      .where('email', '==', userEmail)
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          firebase.firestore().collection(FirebaseConstants.customers).doc(doc.id).update({
            plan: plan
          });
        });
      });
  }

  waitForCustomerNumberToBeSetWithDbListeningToChangesByEmail() {
    let email = this.authService.returnUser();
    return new Promise((resolve, reject) => {
      firebase.firestore().collection(FirebaseConstants.customers)
        .where('email', '==', email)
        .onSnapshot((snap) => {
          snap.forEach((doc) => {
            if (doc.data().kundennummer != 0) {
              resolve(doc.data().kundennummer);
            }
          });
        });
    });
  }

  retreiveUserPlan(userEmail: string): Promise<any> {
    return firebase.firestore().collection(FirebaseConstants.customers)
      .where('email', '==', userEmail)
      .get()
      .then((snap) => {
        console.log("snap", snap);
        if (snap.docs.length > 0) {
          return snap.docs[0].data().plan || false;
        } else {
          return null;
        }
      }).catch((err) => {
        return null;
  });
  }

  retreiveCustomerNumber() {
    let userEmail = this.authService.returnUser();
    return firebase.firestore().collection(FirebaseConstants.customers)
      .where('email', '==', userEmail)
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          return doc.data().kundennummer;
        });
      });
  }

  

  saveZvooveAPIKeys(data) {
    return this.getProfile().get().then((snap) => {
      let docs = snap.docs;
      let docLength = docs.length;
      if (docLength > 0) {
        var id = docs[0].id;
        return firebase.firestore().collection(FirebaseConstants.branding).doc(id).update({
          zvoove: data.zvoove,
          zvooveBewerberApiKey: data.zvooveBewerberApiKey || "",
          zvooveBewerberApiLink: data.zvooveBewerberApiLink || "",
          zvooveStellenApiKey: data.zvooveStellenApiKey || "",
          zvooveStellenApiLink: data.zvooveStellenApiLink || "",
          zvoooveNextLevelApiKey: data.zvoooveNextLevelApiKey || "",
          zvooveNextLevelApiLink: data.zvooveNextLevelApiLink || "",
          zvooveBewerberApiLinkById: data.zvooveBewerberApiLinkById || "",
          zvooveCreateBewerberApiLink: data.zvooveCreateBewerberApiLink || "",
          username: data.username || "",
          password: data.password || "",
          AIMatchingType: data.AIMatchingType || "",
          updated: new Date(),
          status: "aktiv"
        })
      } else {
        return false;
      }
    })
  }

  saveTalentionAPIKeys(data) {
    return this.getProfile().get().then((snap) => {
      let docs = snap.docs;
      let docLength = docs.length;
      if (docLength > 0) {
        var id = docs[0].id;
        return firebase.firestore().collection(FirebaseConstants.branding).doc(id).update({
          talention: data.talention,
          talentionClientId: data.talentionClientId || "",
          talentionClientSecret: data.talentionClientSecret || "",
          talentionAccountId: data.talentionAccountId || "",
          talentionAccountOption: data.talentionAccountOption || "",
          talentionAccountOptionBranches: data.talentionAccountOptionBranches || "",
          talentionMonaStatusEingang: data.talentionMonaStatusEingang || "",
          talentionMonaStatusImInterview: data.talentionMonaStatusImInterview || "",
          talentionMonaStatusInterviewAbgeschlossen: data.talentionMonaStatusInterviewAbgeschlossen || "",
          talentionMonaStatusProfilErstellt: data.talentionMonaStatusProfilErstellt || "",
          talentionMonaStatusProfilVersendet: data.talentionMonaStatusProfilVersendet || "",
          AIMatchingType: data.AIMatchingType || "",
          updated: new Date(),
          status: "aktiv"
        })
      } else {
        return false;
      }
    })
  }

  saveCompleetAPIKeys(data) {
    return this.getProfile().get().then((snap) => {
      let docs = snap.docs;
      let docLength = docs.length;
      if (docLength > 0) {
        var id = docs[0].id;
        return firebase.firestore().collection(FirebaseConstants.branding).doc(id).update({
          compleet: data.compleet,
          compleetAPIKeys: data.compleetAPIKeys || "",
          updated: new Date(),
          AIMatchingType: data.AIMatchingType || "",
          status: "aktiv"
        })
      } else {
        return false;
      }
    })
  }

  saveIndexAPIKeys(data) {
    return this.getProfile().get().then((snap) => {
      let docs = snap.docs;
      let docLength = docs.length;
      if (docLength > 0) {
        var id = docs[0].id;
        return firebase.firestore().collection(FirebaseConstants.branding).doc(id).update({
          index: data.index,
          indexLogin: data.indexLogin || "",
          indexPassword: data.indexPassword || "",
          updated: new Date(),
        })
      } else {
        return false;
      }
    })
  }

  saveZapierAPIKeys(data) {
    return this.getProfile().get().then((snap) => {
      let docs = snap.docs;
      let docLength = docs.length;
      if (docLength > 0) {
        var id = docs[0].id;
        return firebase.firestore().collection(FirebaseConstants.branding).doc(id).update({
          zapier: data.zapier,
          updated: new Date(),
          status: "aktiv"
        })
      } else {
        return false;
      }
    })
  }

  saveCustomIntegrationAPIKeys(data) {
    return this.getProfile().get().then((snap) => {
      let docs = snap.docs;
      let docLength = docs.length;
      if (docLength > 0) {
        var id = docs[0].id;
        return firebase.firestore().collection(FirebaseConstants.branding).doc(id).update({
          customIntegration: data.customIntegration,
          updated: new Date(),
          status: "aktiv"
        })
      } else {
        return false;
      }
    })
  }

  saveTalent360APIKeys(data) {
    return this.getProfile().get().then((snap) => {
      let docs = snap.docs;
      let docLength = docs.length;
      if (docLength > 0) {
        var id = docs[0].id;
        return firebase.firestore().collection(FirebaseConstants.branding).doc(id).update({
          talent360: data.talent360,
          talentClientId: data.talentClientId || "",
          talentClientSecret: data.talentClientSecret || "",
          talentAPIBaseUrl: data.talentAPIBaseUrl || "",
          talentAccessTokenUrl: data.talentAccessTokenUrl || "",
          talentPublicAudience: data.talentPublicAudience || "",
          updated: new Date(),
          status: "aktiv"
        })
      } else {
        return false;
      }
    })
  }

  getUpdates() {
    return this.updates;
  }

  addUpdate(update) {
    return this.updates.add(update);
  }

  getUpdateById(id) {
    return this.updates.doc(id);
  }

  updateUpdate(id, update) {
    return this.updates.doc(id).update(update);
  }

  deleteUpdate(id) {
    return this.updates.doc(id).delete();
  }


}
